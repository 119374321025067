<template>
  <div class="kvz-materials-container">
    <div class="text-context">{{ getTitile() }}</div>
    <div class="option-context w-100">
      <kvz-back-to
        :label="backTo[selectedLanguage]"
        name="materials"
        :center="true"
      />
      <router-link
        v-for="materialType in contextMaterial.itemsInMainContext"
        :key="materialType.path"
        class="m-3"
        :to="{
          name: 'materialsContext',
          params: {
            context: contextMaterial.mainContext,
            parent: materialType.path
          }
        }"
      >
        {{ materialType[selectedLanguage] }}
      </router-link>
    </div>
    <div class="card-wrapper">
      <kvz-card
        v-for="material in currentParentContextCards"
        :id="material.path"
        :key="material.path"
        :recycle="material.recycle"
        :img="material.img"
        :label="material[selectedLanguage]"
        @openContext="openDetails"
      />
    </div>
  </div>
</template>
<script>
import * as materials from "@/crm/materials";
import kvzBackTo from "@/components/kvz-back-to.vue";
export default {
  components: { kvzBackTo },
  data() {
    return {
      materials: materials,

      backTo: {
        EN: "ALL CATEGORIES",
        IT: "TUTTE LE CATEGORIE"
      }
    };
  },
  computed: {
    selectedLanguage() {
      return this.$store.state.values.language;
    },
    contextMaterial() {
      const id = this.$route.params.context.toUpperCase();
      return materials[id];
    },
    currentParentContextCards() {
      if (!this.$route.params) {
        return [];
      }
      return materials[
        `${this.$route.params.context}_${this.$route.params.parent}`.toUpperCase()
      ];
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$router.push({
        name: "materialsContext",
        params: {
          context: this.$route.params.context,
          parent: this.contextMaterial.itemsInMainContext[0].path
        }
      });
    });
  },
  methods: {
    getTitile() {
      return materials.materials.find((element) => {
        return element.path === this.$route.params.context;
      })[this.selectedLanguage];
    },
    changeContext(id) {
      this.$router.push({
        name: "materialsContext",
        params: { context: this.$route.params.context, parent: id }
      });
    },
    openDetails(id) {
      this.$router.push({
        name: "materialsContextParent",
        params: {
          context: this.$route.params.context,
          parent: this.$route.params.parent,
          child: id
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/_function.scss";
.kvz-materials-container {
  padding: toRem(28) 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .text-context {
    font-style: normal;
    font-weight: 700;
    font-size: toRem(14);
    line-height: toRem(20);
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--blue-color);
  }
  .option-context {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.card-wrapper {
  margin: toRem(40) auto;
  display: grid;
  gap: toRem(69) toRem(22);
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 860px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
navbar {
  :deep(.navbar-nav) {
    --bs-nav-link-color: var(--primary-color);
    --bs-nav-link-hover-color: var(--primary-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
:deep(a:not(.backTo)) {
  color: var(--primary-color);
  text-decoration: none;
  margin: 0 toRem(12);
  font-style: normal;
  font-weight: 500;
  font-size: toRem(14);
  line-height: toRem(20);
  letter-spacing: toRem(0.8);
  position: relative;
  text-transform: uppercase;

  &:hover:not(.navbar-brand) {
    opacity: 80%;
    &::after {
      content: "";
      position: absolute;
      border-top: 2px solid var(--blue-color);
      left: 50%;
      transform: translate(-50%, 0);
      bottom: -4px;
      width: toRem(12);
      height: 0px;
    }
  }
}
.active:not(.navbar-brand),
.router-link-active:not(.navbar-brand) {
  font-weight: 700 !important;
  opacity: 1 !important;
  &::after {
    content: "";
    position: absolute;
    border-top: 2px solid var(--blue-color);
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -4px;
    width: toRem(32) !important; // override hover effect
    height: 0px;
  }
}
</style>
