export const consulting = {
  title: "kvz consulting",
  description: [
    {
      EN: "Since 2021, with the opening of a dedicated in-house department, Kiefer von Zoe®  has made the knowledge and experience of its managers available to Brands in the footwear industry.",
      IT: "Dal 2021, con il lancio di una divisione aziendale dedicata, Kiefer von Zoe®  mette a disposizione dei Brand l’esperienza maturata dai suoi manager nel settore calzaturiero."
    },
    {
      EN: "With a focus on internal components for footwear, KvZ Consulting offers customised advice that is built around the specific needs of each client. Our complete project management services range from goal-setting in terms of performance and sustainability up to the creation of prototypes and samples",
      IT: "Nell’ambito dei componenti interni per calzature, KvZ Consulting offre un’assistenza tailor made a partire dalle specifiche esigenze del cliente. La divisione è in grado di offrire un servizio di project management completo, dalla definizione degli obiettivi di prestazione e sostenibilità, fino alla realizzazione di prototipi e campionature."
    }
  ],
  keyPoint: [
    {
      img: "idea",
      EN: "Sector expertise",
      IT: "Expertise di settore"
    },
    {
      img: "sustainability",
      EN: "Sustainability and innovation",
      IT: "Sostenibilità e innovazione"
    },
    {
      img: "help",
      EN: "Tailor-made consulting services",
      IT: "Assistenza tailor made"
    },
    {
      img: "list",
      EN: "Integrated project management service",
      IT: "Servizio di project managment completo"
    }
  ]
};
