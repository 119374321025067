<template>
  <div class="flip-card">
    <div class="flip-card-inner">
      <div class="flip-card-front">
        <div class="imageMask" />
        <img :src="helper.getImage(imageName)" />
        <div class="title" v-html="backCard.title[selectedLanguage]" />
      </div>
      <div class="flip-card-back">
        <div class="title" v-html="backCard.title[selectedLanguage]" />
        <div class="description">
          {{ backCard.description[selectedLanguage] }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as helper from "@/helper/helper";
export default {
  props: {
    backCard: {
      type: Object,
      default: () => {},
      required: true
    },

    imageName: {
      type: String,
      default: "",
      required: true
    }
  },
  data() {
    return {
      helper: helper
    };
  },
  computed: {
    selectedLanguage() {
      return this.$store.state.values.language;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/function";
@import "@/scss/color";
.flip-card {
  width: 100%;
  height: toRem(250);
  position: relative;
  background-color: transparent;
  perspective: 1000px;
}
.flip-card-front {
  .imageMask {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.55) 100%
    );
  }
  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .title {
    z-index: 2;
    position: absolute;
    color: $white;
    width: 100%;
    font-style: normal;
    bottom: 2rem;
    font-size: toRem(16);
    line-height: toRem(22);
    text-align: center;
  }
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
// .flip-card .flip-card-inner {
//   transform: rotateY(180deg);
// }

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  background-color: var(--blue-color);
  color: $black-light;
  transform: rotateY(180deg);
  padding: 0.5rem;
  .title {
    height: 2rem;
  }
  .description {
    font-weight: 400;
    height: calc(100% - 2rem);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
