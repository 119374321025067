<template>
  <div class="container-fluid my-5 px-5">
    <kvz-privacy-en v-if="selectedLanguage === 'EN'" />
    <kvz-privacy-it v-if="selectedLanguage === 'IT'" />
  </div>
</template>
<script>
export default {
  computed: {
    selectedLanguage() {
      return this.$store.state.values.language;
    }
  }
};
</script>
