<template>
  <div class="kvz-homepage">
    <div id="image-container">
      <div
        v-for="image in homepage"
        :key="image.id"
        :class="[`image order-${randomOrder[image.id]}`]"
        :style="`height:${getRandomDimension()}rem;margin:${getRandomMargin()}`"
      >
        <img
          v-if="image.img"
          :src="helper.getImage(image.img)"
          loading="lazy"
          alt=""
        />
        <video
          v-if="image.video"
          class="video-loop"
          autoplay
          loop
          :muted="true"
          :controls="false"
          :src="helper.getVideo(image.video)"
          loading="lazy"
          alt=""
          height="400"
          width="400"
        />
        <div class="description">{{ image[selectedLanguage] }}</div>
      </div>
    </div>
    <!-- <img id="template-image" src="#" class="image" hidden="hidden" />
    <video id="template" src="#" class="video" hidden="hidden" /> -->
  </div>
</template>
<script>
import { homepage } from "@/crm/homePage";
import * as helper from "@/helper/helper";
export default {
  data() {
    return {
      homepage: homepage,
      randomOrder: Array.from(Array(homepage.length).keys()),
      helper: helper
    };
  },
  computed: {
    selectedLanguage() {
      return this.$store.state.values.language;
    }
  },
  mounted() {
    this.randomOrderFunction();
  },
  methods: {
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
    },
    getRandomMargin() {
      return `${this.getRandomInt(3, 6)}rem ${this.getRandomInt(
        5,
        45
      )}% ${this.getRandomInt(3, 6)}rem `;
    },
    getRandomDimension() {
      return this.getRandomInt(22, 27);
    },
    randomOrderFunction() {
      let nums = Array.from(Array(homepage.length).keys());
      let ranNums = [];
      let i = nums.length;
      let j = 0;

      while (i--) {
        j = Math.floor(Math.random() * (i + 1));
        ranNums.push(nums[j]);
        nums.splice(j, 1);
      }
      this.randomOrder = ranNums;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/_function.scss";
.kvz-homepage {
  padding: toRem(90) 0;
}

#image-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
}

.image {
  zoom: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  // Large devices (desktops, 992px and up)
  @media (max-width: 992px) {
    zoom: 0.8;
  }
  // Medium devices (tablets, 768px and up)
  @media (max-width: 768px) {
    zoom: 0.6;
  }
  @media (max-width: 576px) {
    zoom: 0.4;
  }
  &:hover {
    img {
      border-radius: toRem(40);
    }
    .video-loop {
      border-radius: toRem(40);
    }
    .description {
      opacity: 1;
    }
  }
  img {
    height: 100%;
    border-radius: 1rem;
    -webkit-transition: border-radius 0.5s;
    transition: border-radius 0.5s;
  }
  .description {
    opacity: 0;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
    max-width: 70%;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: toRem(14);
    line-height: toRem(17);
    color: var(--primary-color);
    margin-top: 1rem;
  }
  .video-loop {
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
    -webkit-transition: border-radius 0.5s;
    transition: border-radius 0.5s;
  }
}
</style>
