<template>
  <div :class="['nav-item follow my-2 me-4']" @click="(e) => toggleDropdown(e)">
    <div :class="[{ selected: isOpen }]">
      {{ title }}
    </div>
    <transition name="fade" apear>
      <div v-if="isOpen" class="sub-menu d-flex flex-column px-2">
        <div
          v-for="(social, key) in socialList.items"
          :key="key"
          class="my-3 position-relative"
        >
          <a :href="social.link" target="_blank">{{ social.label }}</a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { socialList } from "@/crm/common";
export default {
  props: { title: { type: String, default: "", required: true } },
  data() {
    return {
      isOpen: false,
      socialList: socialList
    };
  },
  mounted() {
    document.addEventListener("click", () => {
      this.isOpen = false;
    });
  },
  methods: {
    toggleDropdown(e) {
      e.stopPropagation();
      this.isOpen = !this.isOpen;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/function";
.nav-item {
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: toRem(16);
  line-height: toRem(16);
  letter-spacing: toRem(0.8);
  position: relative;
  text-transform: uppercase;
  &:hover::after {
    content: "";
    position: absolute;
    border-top: 2px solid var(--blue-color);
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -4px;
    width: toRem(12);
    height: 0px;
  }
  .selected::after {
    content: "";
    position: absolute;
    border-top: 2px solid var(--blue-color);
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -4px;
    width: toRem(32);
    height: 0px;
  }
}
.sub-menu {
  position: absolute;
  z-index: 1;
  top: toRem(30);
  background: var(--background-color-primary);
  // div {
  //   &::after {
  //     content: "";
  //     position: absolute;
  //     border-top: 2px solid var(--blue-color);
  //     left: 50%;
  //     transform: translate(-50%, 0);
  //     bottom: -4px;
  //     width: toRem(12);
  //     height: 0px;
  //   }
  //   &:hover::after {
  //     content: "";
  //     position: absolute;
  //     border-top: 2px solid var(--blue-color);
  //     left: 50%;
  //     transform: translate(-50%, 0);
  //     bottom: -4px;
  //     width: toRem(32);
  //     height: 0px;
  //   }
  // }
  a {
    margin: 0;
    &:hover::after {
      content: inherit !important;
    }
  }
}
</style>
