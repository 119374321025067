<template>
  <div class="container-fluid custom-container">
    <div class="row">
      <div class="col-12 col-lg-3">
        <div class="header-wrapper">
          <h4 class="title">
            {{ contacts[selectedLanguage] }}
          </h4>
          <span class="line" />
        </div>
        <div class="data-container">
          <div v-for="(contact, key) in contacts.data" :key="key">
            <div
              class="data-title d-flex align-items-center justify-content-start"
            >
              <div class="line short" />
              <h6>{{ contact[selectedLanguage] }}</h6>
            </div>
            <div v-if="contact.value" class="data-content">
              {{ contact.value }}
            </div>
            <div v-if="contact.doc" class="data-content fs-6">
              <router-link :to="{ name: contact.linkTo }" target="_blank">
                {{ contact.doc[selectedLanguage] }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="d-none d-lg-block col-lg-1" />
      <div class="col-12 col-lg-8">
        <div class="header-wrapper">
          <h4 class="title">
            {{ address[selectedLanguage] }}
          </h4>
          <span class="line" />
        </div>

        <div class="map-wrapper">
          <MapboxMap
            style="height: 400px"
            :access-token="accessToken"
            map-style="mapbox://styles/giacomofl/cl87i6oqg008f14o6gmlcwvpv"
            :center="center"
            :zoom="zoom"
          >
            <MapboxMarker :lng-lat="center">
              <img src="@/assets/marker.png" alt="" />
            </MapboxMarker>
          </MapboxMap>
        </div>
        <div class="d-flex align-items-center justify-content-start mt-3">
          <div class="line short" />
          <span class="addess m-0 ms-1">{{ address.address }}</span>
        </div>
        <div
          class="distance-wrapper d-flex align-items-center justify-content-start"
        >
          <img src="@/assets/distance.png" alt="distance" />
          <div class="separator mx-3" />
          <div
            class="table-wrapper d-flex flex-column align-items-start justify-content-center"
          >
            <span v-for="(row, key) in address.table" :key="key" class="m-0"
              >{{ row.location[selectedLanguage] }} - {{ row.distance }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { contacts, address } from "@/crm/contacts";
import { MapboxMap, MapboxMarker } from "@studiometa/vue-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
export default {
  components: { MapboxMap, MapboxMarker },
  setup() {
    const center = [8.95592, 45.54496];
    const zoom = 17;
    const accessToken =
      "pk.eyJ1IjoiZ2lhY29tb2ZsIiwiYSI6ImNrOWxkamVhYzAzZW4zbHJ0NTRnOGNzaTgifQ.yYjeSPFynDdrYSPon_HrmQ";
    return {
      center,
      zoom,
      accessToken
    };
  },
  data() {
    return {
      contacts: contacts,
      address: address
    };
  },
  computed: {
    selectedLanguage() {
      return this.$store.state.values.language;
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
@import "@/scss/function";
.header-wrapper {
  display: flex;
  align-items: center;
  margin-top: toRem(80);
  .title {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 700;
    font-size: toRem(24);
    line-height: toRem(24);
    text-transform: uppercase;
    color: var(--primary-color);
    margin: 0 toRem(8) 0 0;
  }
}
.data-container {
  .data-title {
    &:first-child {
      margin-top: toRem(40);
    }
    margin-top: toRem(32);
    text-transform: uppercase;
    h6 {
      margin: 0 0 0 toRem(4);
      font-style: normal;
      font-weight: 700;
      font-size: toRem(20);
      line-height: toRem(22);
      color: var(--primary-color);
    }
  }
  .data-content {
    margin-top: toRem(4);
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: var(--primary-color);
  }
}
.line {
  width: 100%;
  height: 2px;
  background: var(--blue-color);
  &.short {
    width: toRem(16);
  }
}
.map-wrapper {
  margin-top: toRem(27);
  border-radius: toRem(16);
  overflow: hidden;
}
.distance-wrapper {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: toRem(15);
  line-height: toRem(30);
  color: var(--primary-color);
  margin: toRem(32) 0 toRem(180);
  .separator {
    width: 1px;
    height: toRem(105);
    background: var(--blue-color);
  }
}
.addess {
  font-style: normal;
  font-weight: 400;
  font-size: toRem(15);
  line-height: toRem(22);
  color: var(--primary-color);
}
</style>
