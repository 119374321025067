import { createApp } from "vue";
import App from "@/App.vue";
import store from "@/store/store";
import router from "@/router/router";
import "bootstrap";
import "@/scss/main.scss";

const app = createApp(App).use(store).use(router);

const files = require.context("./", true, /\.vue$/i);
files.keys().map(function (key) {
  app.component(key.split("/").pop().split(".")[0], files(key).default);
});

app.mount("#app");
