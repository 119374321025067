<template>
  <div v-if="article">
    <div class="coverArticle">
      <img
        :src="helper.getImage(article.coverImage)"
        :alt="articleName"
        class="coverImage"
      />
      <kvz-back-to :label="backToLabel[selectedLanguage]" name="latest" />
    </div>
    <div class="article-wrapper container-fluid custom-container">
      <div class="row d-flex justify-content-center">
        <div class="col-12 col-lg-5 title">
          {{ article.title[selectedLanguage] }}
        </div>
        <div class="col-12 col-lg-7 article">
          <div v-for="(section, index) in article.content" :key="index">
            {{}}
            <div v-if="section.title" class="article--title">
              {{ section.title[selectedLanguage] }}
            </div>
            <div v-if="section.quote" class="article--quote">
              {{ section.quote[selectedLanguage] }}
            </div>
            <div
              v-if="section.paragraphs && section.paragraphs.length !== 0"
              :class="['article--paragraphs', { small: section.title }]"
            >
              <div
                v-for="(paragraph, id) in section.paragraphs"
                :key="id"
                v-html="paragraph[selectedLanguage]"
              />
            </div>
          </div>
        </div>
        <div
          v-if="article.sectionImages && article.sectionImages.length !== 0"
          class="row imageWrapper"
        >
          <div
            v-for="(image, id) in article.sectionImages"
            :key="id"
            class="cardImage col-12 col-lg"
          >
            <kvz-flip-card :image-name="image.img" :back-card="image.text" />
          </div>
        </div>
        <div v-if="article.notes" class="row bottom">
          <h5>{{ notesLabel[selectedLanguage] }}</h5>
          <div v-for="(note, id) in article.notes" :key="id" class="item">
            {{ note[selectedLanguage] }}
          </div>
        </div>
        <div v-if="article.sources" class="row bottom">
          <h5>{{ sourcesLabel[selectedLanguage] }}</h5>
          <div v-for="(source, id) in article.sources" :key="id" class="item">
            {{ source[selectedLanguage] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as articleList from "@/crm/latest";
import * as helper from "@/helper/helper";
import kvzBackTo from "@/components/kvz-back-to.vue";
export default {
  components: { kvzBackTo },
  data() {
    return {
      articleName: "",
      helper: helper,

      backToLabel: {
        IT: "TORNA A TUTTE LE NEWS",
        EN: "BACK TO THE NEWS"
      },
      notesLabel: {
        IT: "NOTE",
        EN: "NOTES"
      },
      sourcesLabel: {
        IT: "FONTI",
        EN: "SOURCE"
      }
    };
  },
  computed: {
    article() {
      return articleList[this.articleName];
    },
    selectedLanguage() {
      return this.$store.state.values.language;
    }
  },
  mounted() {
    this.getCurrentArticleName();
  },
  methods: {
    getCurrentArticleName() {
      this.articleName = this.$route.params.id;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/function";
@import "@/scss/color";
.coverArticle {
  position: relative;
  .coverImage {
    width: 100%;
    height: 60vh; //toRem(460);
    object-fit: cover;
  }
}

.article-wrapper {
  margin: toRem(55) auto;
}
.row {
  color: var(--article-color);
}
.title {
  color: var(--primary-color);
  font-weight: 900;
  font-size: toRem(40);
  line-height: toRem(52);
  letter-spacing: 1.6px;
  margin-bottom: 2rem;
}
.article {
  font-style: normal;
  font-size: toRem(24);
  line-height: toRem(30);
  &--title {
    font-weight: 700;
    margin-top: 4rem;
    margin-bottom: toRem(10);
  }
  &--paragraphs {
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 2rem;
    &.small {
      // font-size: toRem(16);
      font-size: 1.25rem;
      line-height: 1.5rem;
    }

    & > div {
      margin-bottom: toRem(30);
      line-height: 2rem;
    }
  }
  &--quote {
    font-style: normal;
    font-size: toRem(24);
    line-height: toRem(30);
    margin-bottom: toRem(26);
    padding-left: 1rem;
    position: relative;
    &::before {
      content: "";
      background: $blue;
      width: 4px;
      height: 100%;
      position: absolute;
      left: 0;
    }
  }
}
.cardImage {
  margin-bottom: 1rem;
  &:last-child {
    margin: 0;
  }
}
.bottom {
  margin: 2rem 0 0;
  h5 {
    font-weight: 700;
    font-size: toRem(16);
    line-height: toRem(22);
  }
  .item {
    font-weight: 400;
    font-size: toRem(13);
    line-height: toRem(22);
  }
}
</style>
