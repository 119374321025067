<template>
  <div class="fluid-container my-3 p-5">
    <h3 class="info-title">Informazioni Ex Art. 1C 125 L.124/2017</h3>

    <h4 class="info-paragraph">Anno 2020</h4>
    <span class="text">
      L’Agenzia delle Entrate ha erogato un contributo di € 393,00 sotto forma
      di esonero dal versamento del primo acconto IRAP per il periodo di imposta
      1/7/2020-30/6/2021 con scadenza 31/12/2020 ai sensi dell’art. 24 del d.l.
      34/2020. <br />L’Agenzia delle Entrate ha erogato in data 29/6/2020 un
      contributo di € 6.244,00 sotto forma di contributo a fondo perduto ai
      sensi dell’art. 25 del d.l. 34/2020.
    </span>
    <h4 class="info-paragraph">Anno 2018 impresa collegata C.F. 03513590152</h4>
    <span class="text">
      L’Agenzia delle Entrate ha erogato un contributo di € 95.974,00,
      compensato in data 16/7/2018, 16/8/2018, 20/8/2018, 17/9/2018 con causale
      Ricerca e sviluppo L. 190 e s.m.i. 2017. <br />L’Agenzia delle Entrate ha
      erogato un contributo di € 3.183,00, compensato in data 16/7/2018, con
      causale di Credito di imposta per investimenti in beni strumentali nuovi -
      art. 18 d.l. 24/6/2014 n. 91 (anno di sostenimento della spesa 2015 -
      quota utilizzabile nel 2018)
    </span>
    <h4 class="info-paragraph">Anno 2019 impresa collegata C.F. 03513590152</h4>
    <span class="text">
      L’Agenzia delle Entrate ha erogato un contributo di € 94.509,00,
      compensato in data 16/7/2019, 20/8/2019, 16/9/2019 con causale Ricerca e
      sviluppo L. 190 e s.m.i. 2018. <br />L’Agenzia delle Entrate ha erogato un
      contributo di € 3.183,00, compensato in data 16/7/2019, con causale di
      Credito di imposta per investimenti in beni strumentali nuovi - art. 18
      d.l. 24/6/2014 n. 91 (anno di sostenimento della spesa 2015 - quota
      utilizzabile nel 2019)
    </span>
    <h4 class="info-paragraph">Anno 2020 impresa collegata C.F. 03513590152</h4>
    <span class="text">
      L’Agenzia delle Entrate ha erogato un contributo di € 3.698,00 sotto forma
      di esonero dal versamento del saldo IRAP per l’anno di imposta 2019 con
      scadenza 31/7/2020 ai sensi dell’art. 24 del d.l. 34/2020. <br />L’Agenzia
      delle Entrate ha erogato un contributo di € 68,00 sotto forma di esonero
      dal versamento del primo acconto IRAP per l’anno di imposta 2020 con
      scadenza 31/7/2020 ai sensi dell’art. 24 del d.l. 34/2020. <br />L’Agenzia
      delle Entrate ha erogato in data 29/6/2020 un contributo di € 28.113,00
      sotto forma di contributo a fondo perduto ai sensi dell’art. 25 del d.l.
      34/2020.<br />
      L’Agenzia delle Entrate ha erogato un contributo di € 4.050,00 con causale
      Credito di imposta canoni di locazione ai sensi dell’art. 28 d.l. 34/2020
      compensato in data 16/7/2020.
    </span>

    <h4 class="info-paragraph">Anno 2021 impresa collegata C.F. 03513590152</h4>
    <span class="text">
      L’Agenzia delle Entrate ha erogato un contributo di € 1.707,00 con causale
      Credito di imposta sanificazione ai sensi dell’art. 125 d.l. 34/2020
      compensato in data 18/1/2021. <br />L’Agenzia delle Entrate ha erogato in
      data 28/12/2021 un contributo di € 5.133,00 sotto forma di contributo a
      fondo perduto ai sensi dell’art. 1 c. da 16 a 27 del d.l. 73/2021.
      <br />L’Agenzia delle Entrate ha concesso un contributo di € 121.000,00
      con causale Credito di imposta per l’acquisto di beni strumentali nuovi di
      cui all’art. 1 c. 1056 l. 178/2020 (beni di cui all’allegato A alla l.
      232/2016), quanto a € 80.666,66 compensato nel 2022 e quanto a € 40.333,34
      da compensare nel 2023.<br />
      L’Agenzia delle Entrate ha concesso un contributo di € 14.243,00 con
      causale Credito di imposta per l’acquisto di beni strumentali nuovi di cui
      all’art. 1 c. 1054 l. 178/2020 (beni diversi da quelli ricompresi negli
      allegati A e B alla l. 232/2016) compensato il 15/7/2022. Il Ministero
      dello Sviluppo Economico ha concesso in data 24/05/2021 COR 5434916 un
      contributo Nuova Sabatini d.l. 69/2013 importo nominale € 29.874,22
      elemento di aiuto € 29.874,22 incassato il 3/4/2023. La Banca del
      Mezzogiorno Mediocredito Centrale ha concesso in data 10/5/2021 COR
      5280816 una garanzia con causale COVID-19 Fondo di garanzia PMI Aiuto di
      stato SA. 56966 (2020/N), norma misura d.l. 23/2020 importo nominale €
      4.509,69 elemento di aiuto € 4.509,69 Sezione 3.1 della Comunicazione
      della Commissione del 18/3/2020 1863 final e successive modifiche; importo
      nominale € 325.000,00 elemento di aiuto € 325.000,00 Sezione 3.2 della
      Comunicazione della Commissione del 18/3/2020 1863 final e successive
      modifiche.
    </span>
    <h4 class="info-paragraph">Anno 2022 impresa collegata 03513590152</h4>
    <span class="text">
      L’Agenzia delle Entrate ha concesso un contributo di € 101.000,00 con
      causale Credito di imposta per l’acquisto di beni strumentali nuovi di cui
      all’art. 1 c. 1056 l. 178/2020 (beni di cui all’allegato A alla l.
      232/2016), quanto a € 33.666,67 compensato il 15/7/2022 e quanto a €
      67.333,34 da compensare nel 2023.<br />
      L’Agenzia delle Entrate ha concesso un contributo di € 5.500,00 con
      causale Credito di imposta per l’acquisto di beni strumentali nuovi di cui
      all’art. 1 c. 1054 l. 178/2020 (beni diversi da quelli ricompresi negli
      allegati A e B alla l. 232/2016) prenotati nel 2021 ed effettuati nel
      2022, da compensare nel 2023 e nel 2024.
      <br />
      L’Agenzia delle Entrate ha concesso un contributo di € 5.050,00 con
      causale Credito di imposta per l’acquisto di beni strumentali nuovi di cui
      all’art. 1 c. 1054 l. 178/2020 (beni diversi da quelli ricompresi negli
      allegati A e B alla l. 232/2016) effettuati nel 2022, da compensare nel
      2023 e nel 2024.
      <br />
      L’Agenzia delle Entrate ha concesso un contributo di € 1.310,37 con
      causale Credito di imposta imprese non energivore secondo trimestre 2022
      art 3 DL 21/2022 compensato il 17/10/2022.
      <br />
      L’Agenzia delle Entrate ha concesso un contributo di € 1.917,82 con
      causale Credito di imposta imprese non energivore terzo trimestre 2022 art
      6 DL 115/2022 da compensare nel 2023.
      <br />
      L’Agenzia delle Entrate ha concesso un contributo di € 2.405,42 con
      causale Credito di imposta imprese non energivore ottobre-novembre 2022
      art 1 DL 144/2022 da compensare nel 2023.
      <br />
      L’Agenzia delle Entrate ha concesso un contributo di € 1.753,98 con
      causale Credito di imposta imprese non energivore dicembre 2022 art 1 DL
      176/2022 da compensare nel 2023.
      <br />

      L’Agenzia delle Entrate ha concesso un contributo di € 315,34 con causale
      Credito di imposta imprese non gasivore secondo trimestre 2022 art 4 DL
      21/2022 compensato il 17/10/2022.
      <br />
      L’Agenzia delle Entrate ha concesso un contributo di € 434,12 con causale
      Credito di imposta imprese non gasivore ottobre-novembre 2022 art 1 DL
      144/2022 da compensare nel 2023.
      <br />
      L’Agenzia delle Entrate ha concesso un contributo di € 785,96 con causale
      Credito di imposta imprese non gasivore dicembre 2022 art 1 DL 176/2022 da
      compensare nel 2023.
    </span>
  </div>
</template>
<style lang="scss" scoped>
@import "@/scss/function";
.info-title {
  color: var(--accent-color);
  font-size: toRem(28);
  line-height: 1.5;
  letter-spacing: 0.1px;
  margin-bottom: 2rem;
}
.info-paragraph {
  color: var(--blue-color);
  font-size: toRem(20);
  line-height: 1.5;
  margin: 1rem 0;
}
.text {
  color: var(--primary-color);
  font-size: toRem(18);
  line-height: 1.5;
}
</style>
