<template>
  <div class="small-container">
    <div class="coverImage">
      <img class="image" :src="helper.getImage(imageName)" />
      <div class="overlay" />
    </div>
    <div class="consulting-wrapper">
      <div class="row">
        <div class="col-12 col-md-10 col-lg-8">
          <h4>{{ consulting.title }}</h4>
          <div
            v-for="(paragrapgh, key) in consulting.description"
            :key="key"
            class="description"
          >
            {{ paragrapgh[selectedLanguage] }}
          </div>
        </div>
      </div>
    </div>
    <div class="consulting-wrapper">
      <div class="card-wrapper">
        <div
          v-for="(point, key) in consulting.keyPoint"
          :key="key"
          class="keyPoint d-flex flex-column align-items-center justify-content-center"
        >
          <img class="icon" :src="helper.getImage(point.img, true)" />
          <h6 class="m-0">
            {{ point[selectedLanguage] }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as helper from "@/helper/helper";
import { consulting } from "@/crm/consulting";
export default {
  data() {
    return {
      helper: helper,
      consulting: consulting,

      imageName: "KVZ_CONSULTING"
    };
  },
  computed: {
    selectedLanguage() {
      return this.$store.state.values.language;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/_function.scss";
.small-container {
  padding: toRem(32) toRem(40);

  .coverImage {
    width: 100%;
    height: 40vh;

    border-radius: toRem(40);
    position: relative;
    overflow: hidden;
    .image {
      width: 100%;
      height: 40vh;
      object-fit: cover;
      position: absolute;
      // filter: grayscale(90);
      // mix-blend-mode: luminosity;
      top: 0;
      left: 0;
    }
    // .overlay {
    //   background: var(--green-color);
    //   mix-blend-mode: multiply;
    //   width: 100%;
    //   height: 40vh;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    // }
  }
  .consulting-wrapper {
    margin: toRem(80) auto toRem(60);
    padding: 0 toRem(150);
    @media (max-width: 992px) {
      padding: 0 toRem(75);
    }
    @media (max-width: 768px) {
      padding: initial;
    }
    h4 {
      font-style: normal;
      margin: 0;
      font-weight: 700;
      font-size: toRem(32);
      line-height: toRem(40);
      color: var(--primary-color);
      text-transform: uppercase;
      position: relative;
      &::after {
        content: "";
        width: toRem(180);
        height: 2px;
        background: var(--blue-color);
        position: absolute;
        left: 0;
        top: toRem(56);
      }
    }
    .description {
      margin: 2rem 0 0;
      font-style: normal;
      font-weight: 500;
      font-size: toRem(24);
      line-height: toRem(30);
      color: var(--accent-color);
    }
  }
  .card-wrapper {
    margin: 0 auto;
    display: grid;
    gap: toRem(24) toRem(22);
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 992px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .keyPoint {
      border: 1px solid var(--blue-color);
      box-shadow: -6px -6px 16px var(--background-color-primary),
        6px 6px 16px rgba(170, 187, 255, 0.2);
      border-radius: toRem(12);
      padding: toRem(32) 0;
      .icon {
        height: toRem(48);
        margin-bottom: toRem(8);
      }
      h6 {
        text-transform: uppercase;
        font-weight: 500;
        font-size: toRem(22);
        line-height: toRem(30);
        text-align: center;
        letter-spacing: 2px;
        color: var(--accent-color);
      }
    }
  }
}
</style>
