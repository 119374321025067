export const articleList = [
  {
    parseTitle: "KVZ_ESSENTIAL",
    title: {
      EN: "KIEFER VON ZOE Essentials",
      IT: "KIEFER VON ZOE Essentials"
    },
    subtitle: {
      EN: "A look at Kiefer von Zoe’s major innovations in the fashion industry",
      IT: "Uno sguardo alle maggiori innovazioni portate da Kiefer von Zoe nell'industria fashion"
    },
    pupblicationDate: {
      EN: "09/05/2023",
      IT: "09/05/2023"
    },
    coverImage: "LINKEDIN_NEWSLETTER"
  },
  {
    parseTitle: "BEE_KIEFER",
    title: {
      EN: "BEE KIEFER",
      IT: "BEE KIEFER"
    },
    subtitle: {
      EN: "Find out how Kiefer von Zoe® is working to protect bees and preserve biodiversity",
      IT: "Scopri come Kiefer von Zoe® si sta impegnando per proteggere le api e preservare la biodiversità"
    },
    pupblicationDate: {
      EN: "20/12/2022",
      IT: "20/12/2022"
    },
    coverImage: "HEADER"
  }
];
export const BEE_KIEFER = {
  title: {
    EN: "BEE KIEFER",
    IT: "BEE KIEFER"
  },
  coverImage: "HEADER",
  content: [
    {
      paragraphs: [
        {
          IT: "Da oltre 15 anni ci impegniamo a innovare in modo sostenibile, a preservare la biodiversità e a creare un impatto sociale positivo, restituendo alla natura le sue ricchezze attraverso un approvvigionamento sostenibile delle materie prime.",
          EN: "For over 15 years, we’ve been committed to sustainable innovation, the preservation of biodiversity and the generation of positive social impact, restoring the richness of nature by using sustainably sourced raw materials."
        },
        {
          IT: "Nel 2022, questo impegno – animato dal senso di dovere che ci spinge ad agire per consegnare alle generazioni future un mondo migliore di quello che abbiamo ereditato – ha raggiunto una nuova frontiera con il lancio del progetto “BEE KIEFER”.",
          EN: "In 2022, this commitment, invigorated by the sense of duty that pushes us to act so that future generations will inherit a better world, has reached a new frontier with the launch of the BEE KIEFER project."
        },
        {
          IT: "La naturale evoluzione del nostro percorso coincide, infatti, con la decisione di iniziare a dedicarci alla protezione e preservazione di uno dei tesori più preziosi della natura: le api.",
          EN: "And indeed, the natural evolution of our path coincides with the decision to dedicate our time and energy to the protection and preservation of one of nature's most precious treasures: bees."
        }
      ]
    },
    {
      title: {
        EN: "WHY BEES?",
        IT: "PERCHÉ LE API?"
      },
      quote: {
        IT: "“Se le api scomparissero dalla Terra, per l’uomo non resterebbero che quattro anni di vita”",
        EN: "“If the bee disappeared off the face of the Earth, man would only have four years left to live.”"
      },
      paragraphs: [
        {
          IT: "Secondo la FAO, infatti, l’87% delle coltivazioni che nutrono l’uomo si riproduce grazie all’impollinazione, proprio come 3 quarti delle colture più importanti al mondo. Se il numero di api continuerà a diminuire, non sarà solo la nostra sicurezza alimentare a vacillare, ma anche il futuro del nostro ecosistema.",
          EN: "According to the Food and Agriculture Organization of the United Nations (FAO), 87% of crops grown for human consumption reproduce thanks to pollination; the same is true of three-quarters of the most important crops in the world. If the number of bees continues to decline, it won’t only be our food security to waver, but also the future of our ecosystem."
        },
        {
          IT: "Gli insetti impollinatori – e le api in particolare – sono in grave pericolo: si stima che negli ultimi 10 anni siano scomparsi 10 milioni di alveari, 200mila dei quali solo in Italia. (1)",
          EN: "Pollinators (bees in particular) are in grave danger. It is estimated that, in the last 10 years, 10 million beehives have disappeared, 200,000 in Italy alone. (1)"
        },
        {
          IT: "Queste morie di massa di api sono state correlate a pesticidi (in particolare a un gruppo di sostanze chimiche chiamate neonicotinoidi), all’acaro parassita “Varroa destructor” e alla riduzione dell’habitat nativo aggravata dal diffondersi delle monocolture commerciali su larga scala. Ovviamente, anche i cambiamenti climatici hanno messo in difficoltà le specie endemiche di tutto il mondo, al punto che in Europa 1 ape su 10 è a rischio estinzione. (2), (3)",
          EN: "This massive die-off of bees has been correlated to pesticides (in particular one specific group of chemical substances called neonicotinoids), the Varroa destructor parasitic mite, and native habitat destruction that's only exacerbated by large-scale monoculture farming. And it goes without saying that climate change has put native species all over the world at risk, to the degree that in Europe, one out of ten bees is at risk of extinction. (2), (3)"
        }
      ]
    },
    {
      title: {
        EN: "THE PROJECT",
        IT: "IL PROGETTO"
      },

      paragraphs: [
        {
          IT: "BEE KIEFER sostiene l’allevamento di 150 arnie – circa 9 milioni di api (a) – grazie al lavoro di <a style='  text-decoration: underline; text-decoration-color: var(--blue-color);text-decoration-thickness: 2px; text-underline-offset: 4px;' href='http://mielefvg.it/index.html' target='blank'> un apicoltore dall’esperienza pluridecennale</a> e selezionato dal nostro Sustainability Department in uno dei territori più naturali e incontaminati d’Italia: il Friuli-Venezia Giulia.",
          EN: "BEE KIEFER provides support for 150 beehives—about 9 million bees (a)—thanks to the work of <a style='  text-decoration: underline; text-decoration-color: var(--blue-color);text-decoration-thickness: 2px; text-underline-offset: 4px;' href='http://mielefvg.it/index.html' target='blank'>a beekeeper with decades of experience</a> selected by our Sustainability Department, working in one of the most pristine natural areas of Italy: Friuli-Venezia Giulia."
        },
        {
          IT: "Le arnie – suddivise in 9 apiari (b) – sono distribuite lungo tutta la provincia di Udine – dall’ alta pianura friulana alle Prealpi Giulie – e localizzate in aree dove sorgono prevalentemente colture e vegetazioni spontanee. Tutti i siti individuati dal “nostro” apicoltore sono, inoltre, distanti da strade ad alta densità di traffico e zone industriali.",
          EN: "Split among 9 apiaries (b), the beehives are spread along the entire province of Udine, from the Friulian highlands to the Julian Prealps, in areas where mainly crops and wild vegetation grow. Moreover, all the sites selected by ‘our’ beekeeper are far from roads, high-density traffic, and industrial zones."
        },
        {
          IT: "Al fine di garantire il benessere di questi straordinari insetti, tutti i materiali utilizzati per la realizzazione delle arnie sono naturali e l’allevamento segue metodi tradizionali: sia la raccolta dei melari – che avviene solamente quando il miele è totalmente opercolato nei faci (e quindi “maturo”) – che la smielatura sono operazioni completamente manuali, senza l’utilizzo soffiatori né di pompe aspiranti.",
          EN: "In order to guarantee the well-being of these extraordinary insects, only natural materials are used to make the beehives, and they're cared for and harvested using traditional methods. From the collection of honey and honeycombs (which generally happens when the honeycomb faces are covered in operculum, and thus mature) to the extraction of honey, everything is done manually, without the use of blowers or suction pumps."
        },
        {
          IT: "BEE KIEFER è un progetto a più fasi, pensato per evolvere nel tempo raggiungendo risultati sempre più significativi:",
          EN: "BEE KIEFER is a multi-phase project, designed to evolve over time, achieving increasingly significant results over time."
        }
      ]
    }
  ],
  sectionImages: [
    {
      img: "FASE_1",
      text: {
        title: {
          IT: "<b style='font-weight:700; text-transform:uppercase'>1. Piantumazione</b> (dal 2022)",
          EN: "<b style='font-weight:700; text-transform:uppercase'>1. Planting</b> (launched in 2022)"
        },
        description: {
          IT: "Dal 2022, presso i nostri headquarter, ci impegniamo nella piantumazione e nella cura di alcuni tra i fiori preferiti dalle api – come la lavanda e la “Cephalaria Transsylvanica” – con l’obbiettivo di offrire riparo e ristoro alle colonie che sorvolano i nostri prati.",
          EN: "Since 2022, at our headquarters, we've been committed to the planting and cultivation of flowers that bees love, such as lavender and Cephalaria Transsylvanica with the goal of offering habitat and food to the bee colonies near our fields."
        }
      }
    },

    {
      img: "FASE_2",
      text: {
        title: {
          IT: "<b style='font-weight:700; text-transform:uppercase'>2. Sensibilizzazione</b> (2022)",
          EN: "<b style='font-weight:700; text-transform:uppercase'>2. Raising awareness</b> (2022)"
        },
        description: {
          IT: "Se la biodiversità è al centro del nostro impegno, l’ape è al centro della biodiversità. Con l’obbiettivo di sensibilizzare sull’importanza della salvaguardia di questi straordinari insetti, nel 2022 abbiamo brandizzato e distribuito oltre 200 chili del miele prodotto dall’apicoltore da noi selezionato.",
          EN: "Biodiversity is at the centre of our commitment, and bees are at the centre of biodiversity. With the goal of raising awareness about how important it is to protect these extraordinary insects, in 2022 we’ve branded and distributed over 200 kilos of honey produced by our selected beekeeper."
        }
      }
    },
    {
      img: "FASE_3",
      text: {
        title: {
          IT: "<b style='font-weight:700; text-transform:uppercase'>3. Adozione</b> (dal 2023)",
          EN: "<b style='font-weight:700; text-transform:uppercase'>3. Adoption</b> (2023)"
        },
        description: {
          IT: "Dal 2023, il nostro impegno si traduce anche nell’adozione di un alveare. L’obiettivo di Kiefer von Zoe® è, infatti, quello di dare sostegno e supporto continuo all’eccezionale lavoro del “nostro” apicoltore, un vero e proprio custode della biodiversità.",
          EN: "Starting in 2023, we at Kiefer von Zoe® have expressed our commitment to bees by adopting a beehive. Our goal is to provide continued support to the exceptional work done by our beekeeper, a true custodian of biodiversity."
        }
      }
    }
  ],
  notes: [
    {
      IT: "(a): Il termine “arnia” è sinonimo di “alveare” e indica la casa delle api. Si tratta cioè delle casette di legno colorate che ospitano una colonia di insetti ciascuna. Ogni colonia è composta da una sola regina, dalle operaie (femmine) e dai fuchi (maschi). Il numero di api per colonia",
      EN: "(a): These beehives are colourful wooden boxes that contain one bee colony. Each colony is composed of a single queen, worker bees (females) and drones (males). The population of a single colony can reach 50,000-60,000 bees in spring/summer, then decreases sharply in winter."
    },
    {
      IT: "(b): Con il termine “apiario”, invece, si intende l’insieme di più arnie localizzate nello stesso sito.",
      EN: "(b): Apiaries are defined as the larger grouping of multiple bee boxes (which protect the hives) in the same location."
    }
  ],
  sources: [
    {
      IT: "(1): fonte – Confederazione italiana agricoltori",
      EN: "(1): Confederazione Italiana Agricoltori"
    },
    {
      IT: "(2): fonte – LifeGate",
      EN: "(2): LifeGate"
    },
    {
      IT: "(3): fonte – National Geographic",
      EN: "(3): National Geographic"
    }
  ]
};
export const KVZ_ESSENTIAL = {
  title: {
    EN: "KIEFER VON ZOE Essentials",
    IT: "KIEFER VON ZOE Essentials"
  },
  coverImage: "LINKEDIN_NEWSLETTER",
  content: [
    {
      paragraphs: [
        {
          IT: "Scopri la nuova LinkedIn newsletter “KIEFER VON ZOE Essentials” di Kiefer Von Zoe, una selezione sempre aggiornata delle principali innovazioni tecniche e sostenibili portate da Kiefer Von Zoe nei settori footwear e pelletteria.",
          EN: "Discover the new LinkedIn newsletter 'KIEFER VON ZOE Essentials' by Kiefer Von Zoe, a constantly updated selection of the most important technical and sustainable innovations brought by Kiefer Von Zoe in the footwear and leather goods industries."
        },
        {
          IT: "Per non perdere nessun aggiornamento, iscriviti <a href='https://www.linkedin.com/newsletters/kiefer-von-zoe-essentials-7060287397275537408/?displayConfirmation=false' target='_blank'>qui</a>",
          EN: "Subscribe <a href='https://www.linkedin.com/newsletters/kiefer-von-zoe-essentials-7060287397275537408/?displayConfirmation=false' target='_blank'>here</a>, not to miss any update!"
        }
      ]
    }
  ]
};
