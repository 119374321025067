<template>
  <div class="container-fluid custom-container">
    <h3 :class="['title', headerSizeClass, { separator: hasSeparator }]">
      <slot name="title" />
    </h3>
    <div :class="['text']"><slot name="body" /></div>
  </div>
</template>
<script>
export default {
  props: {
    headerSizeClass: {
      type: String,
      default: "fs-32",
      validator(value) {
        return ["fs-32", "fs-30", ""].includes(value);
      }
    },
    hasSeparator: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/function";

.title {
  font-weight: 700;
  line-height: toRem(40);
  letter-spacing: 0px;
  text-align: left;
  color: var(--primary-color);
  position: relative;
  margin-bottom: 2rem;
  &.fs-32 {
    font-size: toRem(32);
  }
  &.fs-30 {
    font-size: toRem(30);
  }
  &.separator:after {
    //border-bottom: 1px solid var(--blue-color);
    content: "";
    position: absolute;
    border-top: 2px solid var(--blue-color);
    left: 0px;
    bottom: -1rem;
    width: toRem(183) !important;
    height: 0px;
  }
}
.text {
  color: var(--primary-color);
  font-size: toRem(24);
  font-weight: 500;
  line-height: toRem(30);
  letter-spacing: 0px;
  text-align: left;
}
</style>
