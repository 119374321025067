const state = {
  activeTheme: localStorage.getItem("user-theme"),
  language: localStorage.getItem("language"),
  worlds: null,
  catalogue: null
};

const getters = {
  // teamTypeOptions: (state) =>
  //   state.teamTypes?.map((team) => ({
  //     value: team.id,
  //     label: team.name
  //   }))
};

const actions = {
  "change-theme"({ commit, state }, payload) {
    commit("mutate", () => (state.activeTheme = payload));
  },
  "change-language"({ commit, state }, payload) {
    commit("mutate", () => (state.language = payload));
  }
  // "remove-idea"({ commit, state }, payload) {
  //   //dispatch('spinner/show', {type: 'non-blocking'}, {root: true});
  //   const newList = state.ideas.filter((idea) => {
  //     return idea.name !== payload.name;
  //   });
  //   commit("mutate", () => (state.ideas = newList));
  // }
  // "edit-idea"({ commit, state }, payload) {},

  // 'fetch-estates'({commit, state, dispatch}) {

  //   dispatch('spinner/show', {type: 'non-blocking'}, {root: true});

  //   return ValueService.show('Estate')
  //     .then(res => {
  //       commit('mutate', () => state.estates = res.data);
  //     })
  //     .finally(() => dispatch('spinner/hide', {}, {root: true}));

  // },

  // 'fetch-worlds'({commit, state, dispatch}) {

  //   dispatch('spinner/show', {type: 'non-blocking'}, {root: true});

  //   return ValueService.show('World')
  //     .then(res => {
  //       commit('mutate', () => state.worlds = res.data);
  //     })
  //     .finally(() => dispatch('spinner/hide', {}, {root: true}));

  // },

  // 'fetch-catalogue'({commit, state, dispatch}) {

  //   commit('mutate', () => state.catalogue = null);

  //   dispatch('spinner/show', {type: 'non-blocking'}, {root: true});

  //   return ValueService.index()
  //     .then(res => {
  //       commit('mutate', () => state.catalogue = res.data);
  //     })
  //     .finally(() => dispatch('spinner/hide', {}, {root: true}));

  // },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { mutate: (state, fn) => fn() }
};
