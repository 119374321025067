<template>
  <div class="container-fluid kvz-container">
    <kvz-article
      v-for="(article, index) in articleList"
      :key="index"
      :article="article"
    />
  </div>
</template>
<script>
import { articleList } from "@/crm/latest";
export default {
  data() {
    return {
      articleList: articleList
    };
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/_function.scss";
.kvz-container {
  padding-top: toRem(100);
  max-width: 90%;
  margin: 0 auto;
  padding-bottom: 5rem;
}
</style>
