<!-- <h6 class="d-flex justify-content-center fs-4">
      <router-link to="/" target="_blank"> www.kiefervonzoe.com </router-link>
    </h6> -->
<template>
  <div class="privacy">
    <h5 class="d-flex justify-content-center fs-3 mb-3">
      <b>PRIVACY POLICY</b>
    </h5>
    <div class="subtitle my-3">
      INFORMATION ON THE PROTECTION OF PERSONAL DATA PURSUANT TO ARTICLES 13 AND
      14 OF REGULATION (EU) 2016/679
    </div>
    <div class="description my-1">
      <slot name="description" />
      <b>KIEFER VON ZOE S.r.l.</b>, with registered office at Via Po 41 - 20015
      Parabiago (Mi), Tel. 0331 422868 - e-mail
      <a href="mailto:commerciale@kiefervonzoe.com"
        >commerciale@kiefervonzoe.com</a
      >, in its capacity as <b>Data Controller</b> (hereinafter, the
      &quot;<b>Company</b>&quot; or the “Controller&quot;), provides the
      following information common to the processing of personal data carried
      out within the context of its institutional website, accessible by
      electronic means from the address:
      <router-link to="/" target="_blank"> www.kiefervonzoe.it </router-link>
      (hereinafter, the &quot;<b>Site</b>&quot;). <br class="my-3" />In this
      regard, it should be noted that the information is provided only for the
      Site and not for other websites that may be consulted via hypertext links
      or widgets (e.g., social networks) published on the Site, but referring to
      resources outside the Controller&#39;s domain or to the processing that
      may result from the voluntary sending of messages.
    </div>
    <ol class="list my-5" type="1">
      <li class="list my-3">
        CATEGORIES OF DATA SUBJECTS AND PERSONAL DATA PROCESSED
        <br />
        The Controller processes the personal data of natural persons
        (identified or identifiable) who visit and consult the Site or who
        voluntarily interact with the Controller (hereinafter,
        &quot;<b>Users</b>&quot;).
        <br class="my-3" />
        The personal data processed are:
        <ol type="a">
          <li>
            <i>Browsing data</i>: in the course of their normal activity, the
            computer systems and software procedures used to operate the Site
            acquire certain personal data, the <b>transmission of</b> which is
            <b>implicit</b>
            in the use of Internet communication protocols. This category of
            data includes: the IP addresses or domain names of the computers and
            terminals used by users, the URI/URL (Uniform Resource
            Identifier/Locator) addresses of the resources requested, the time
            of the request, the method used to submit the request to the server,
            the size of the file obtained in response, the numerical code
            indicating the status of the response given by the server
            (successful, error, etc.) and other parameters relating to the
            user&#39;s operating system and computer environment;
          </li>
          <li>
            <i>Data communicated</i>: the optional, explicit and voluntary
            sending of messages by filling in and forwarding forms on the Site
            and/or to the Company&#39;s contact addresses or institutional
            profiles/pages on social media (where this possibility is provided
            for) entails the acquisition of the User&#39;s contact data
            necessary to reply, as well as any further personal data included in
            the registration form or in the communications. Specific information
            will be published on the pages of the Site containing the form or
            designed to provide certain services.
          </li>
          <li>
            <i>Cookies and other tracking systems</i>: for more information on
            the type of cookies used, how they are used and their purposes,
            please<router-link :to="{ name: 'cookie' }" target="_blank">
              <b> consult the cookie policy on the Site</b> </router-link
            >. (hereinafter, all &#39;<b>Personal Data</b>&#39;).
          </li>
        </ol>
      </li>
      <li class="list my-3">
        PURPOSES OF THE PROCESSING AND LEGAL BASIS: <br />
        The Controller processes the Personal Data collected in the context of
        the Site for the purposes and on the legal basis indicated in the
        following table:
        <div class="container my-3">
          <div class="row header">
            <div class="col col-2" />
            <div class="col col-5">
              <u><b> What are the PURPOSES of the processing? </b></u>
            </div>
            <div class="col col-5">
              <u><b> What is the LEGAL BASIS for processing? </b></u>
            </div>
          </div>
          <div class="row">
            <div class="col col-2">1</div>
            <div class="col col-5">
              Fulfilment of a legal obligation related to civil, fiscal and
              administrative provisions, EU legislation, standards, codes or
              procedures approved by Authorities and other competent
              Institutions, as well as to comply with requests from the
              competent administrative or judicial authority and, more
              generally, from public entities in compliance with the formalities
              of the law.
            </div>
            <div class="col col-5">
              Fulfilment of a
              <span class="text-danger">legal obligation to</span>
              which the Controller is subject.
            </div>
          </div>
          <div class="row">
            <div class="col col-2">2</div>
            <div class="col col-5">
              To assert and defend its rights, also through extrajudicial
              initiatives and also through third parties, as well as to prevent
              and detect fraudulent activities or abuse of the Site (for
              potentially criminal purposes, such as identity theft,
              cybercrimes, etc.).
            </div>
            <div class="col col-5">
              Pursuit of the
              <span class="text-danger">legitimate interest of</span>
              the Controller.
            </div>
          </div>
          <div class="row">
            <div class="col col-2">3</div>
            <div class="col col-5">
              To enable Users to access and navigate the Site optimally and to
              manage requests received through the Site.
            </div>
            <div class="col col-5">
              Performance of
              <span class="text-danger">
                steps at the User’s request prior to entering into a contract
              </span>
              .
            </div>
          </div>
          <div class="row">
            <div class="col col-2">4</div>
            <div class="col col-5">
              Limited to the Users&#39; browsing data under par. 1 point a., for
              security purposes of the Controller&#39;s systems and to obtain
              statistical information on use of the Site (such as the most
              frequently visited pages, the average time spent on each page), as
              well as to control and administer operation of the Site and to
              improve the services provided.
            </div>
            <div class="col col-5">
              Pursuit of the
              <span class="text-danger">legitimate interest of</span>
              the Controller.
            </div>
          </div>
          <div class="row">
            <div class="col col-2">5</div>
            <div class="col col-5">
              To manage the contact section of the site and therefore to respond
              to any user requests received by filling in the appropriate form
              or by sending communications to the Controller&#39;s e-mail
              address
            </div>
            <div class="col col-5">
              Performance of
              <span class="text-danger">
                steps at the User’s request prior to entering into a contract
              </span>
              .
            </div>
          </div>
        </div>
      </li>
      <li class="list my-3">
        OBLIGATION TO PROVIDE THE REQUESTED DATA AND CONSEQUENCES OF FAILURE TO
        DO SO <br />
        With the exception of that specified for browsing data (and, in the
        specific policy, for the management of cookies), the user is free to
        provide his/her personal data (through forms - on the pages that allow
        it - or with other modalities to the contacts of the Controller) to send
        information requests or to receive commercial communications.<br
          class="my-3"
        />It is understood that failure to provide them, even in part, may
        prevent the Controller from carrying out the User&#39;s request and
        communication activities, as well as from fulfilling any related
        obligations.
      </li>
      <li class="list my-3">
        PROCESSING METHODS <br />
        The Personal Data will be processed by means of both manual and
        automated means exclusively by authorised and specially trained persons.
      </li>
      <li class="list my-3">
        RECIPIENTS/CATEGORIES OF RECIPIENTS OF PERSONAL DATA <br />
        For the purposes set out in this policy:<br />
        <ul style="list-style-type: '\2713'">
          <li>&nbsp;Users&#39; Personal Data may be communicated:</li>
          <ul style="list-style-type: disc">
            <li>
              to those authorised to process data by the Controller (employees
              or collaborators);
            </li>
            <li>
              to third-party service providers of the Controller (including IT
              service providers, hosting providers, web editors, as well as
              companies or entities providing legal or insurance services) who
              will act, where appropriate, as data processors;
            </li>
            <li>
              to companies and third-party professionals appointed to enforce
              rights, interests, claims of the Controller arising from the
              relationship with the Users;
            </li>
            <li>
              to State Administrations, judicial or administrative authorities,
              public and private bodies, also following inspections and audits;
            </li>
            <li>
              to persons who can access the data by virtue of legal provisions
              or secondary or Community legislation.
            </li>
          </ul>
        </ul>
        Only the category of recipients is indicated, as it is subject to
        continuous updates. For an updated list of recipients, Users may contact
        the Controller directly by writing to the contact details given in
        paragraph 9 of this policy.
      </li>
      <li class="list my-3">
        PERSONAL DATA STORAGE PERIODS <br />
        The Personal Data will be kept by the Controller for the time strictly
        necessary for the purpose for which they were collected; specifically,
        the Controller will store:
        <ul style="list-style-type: disc">
          <li>
            Users&#39; browsing data (indicated in paragraph 1, letter a) for
            the duration of the browsing session and, in any case, for no more
            than seven days, except in the event of system failures, in which
            case they will be stored until the problem is resolved;
          </li>
          <li>
            the data communicated by the Users (indicated in paragraph 1, letter
            b):
            <ol type="i">
              <li>
                with regard to personal data communicated by filling in the
                forms on the website, for the time necessary to process the
                relevant request;
              </li>
            </ol>
          </li>
          <li>
            Personal Data whose processing is necessary in connection with legal
            obligations, for the period required by law;
          </li>
        </ul>
        and in any event, for the purposes set out in paragraph 2(2), for a
        maximum period equal to the limitation period for the relevant actions,
        plus a prudential period of six months, in order to ensure the
        Company&#39;s right of defence in respect of possible future litigation
        before a court or administrative authority.
        <br class="my-3" />
        In all cases, upon expiration of the respective time-limits, all
        Personal Data will be erased or rendered anonymous. This is without
        prejudice to the fact that the periods indicated may be extended in
        cases where storage for a longer period is required in the event of
        litigation, requests by competent authorities or pursuant to applicable
        legislation.
      </li>
      <li class="list my-3">
        TRANSFER OF PERSONAL DATA TO A THIRD COUNTRY OR INTERNATIONAL
        ORGANISATION<br />Within the scope of the above-mentioned purposes, your
        data may be transferred to countries within the EU.
      </li>
      <li class="list my-3">
        RIGHTS<br />
        Users may exercise the following rights vis-à-vis the Controller if the
        circumstances apply:
        <ul>
          <li>
            <b>Right of access:</b> allows Users to obtain from the Controller
            confirmation as to whether or not Personal Data concerning them are
            being processed and, where that is the case, to obtain access to
            their Personal Data;
          </li>
          <li>
            <b>Right of rectification:</b> allows Users to obtain
            rectification/addition of inaccurate/incomplete Personal Data;
          </li>
          <li>
            <b>Right to erasure:</b> allows Users to obtain, in the cases
            provided for by the regulation, the erasure of their personal data;
          </li>
          <li>
            <b>Right to restriction of processing:</b> allows Users to obtain,
            in the cases provided for in Article 18(1) of the GDPR, the
            restriction (i.e., the marking of personal data stored with the aim
            of restricting its processing in the future) of the processing of
            their personal data;
          </li>
          <li>
            <b>Right to data portability:</b> allows Users - in cases where
            processing is carried out by automated means on the legal basis of
            contract or consent - to receive in a structured, commonly used and
            machine-readable format, limited to the data provided to the
            Controller, the personal data concerning him/her and similarly the
            right to transmit such data to another data controller.
          </li>
        </ul>
        In addition, Users have the right:
        <ul style="list-style-type: disc">
          <li>
            <b>to object to</b> the processing of their Personal Data for the
            purposes indicated in paragraph 2;
          </li>
          <li>
            as well as, if they consider that the processing of Personal Data
            relating to them carried out through this Site is in breach of the
            GDPR, <b>to lodge a complaint pursuant to</b> art. 77 of the GDPR,
            to the national supervisory authority of the member state of the
            European Union in which the Data Subject has his/her habitual
            residence or place of work or where the alleged breach of his/her
            right occurred (if this state is Italy, the person to whom he/she
            may refer is the Supervisory Authority for the protection of
            personal data) <b>or to take appropriate legal action</b> (art. 79
            of the GDPR).
          </li>
        </ul>
      </li>
      <li class="list my-3">
        CONTACT<br />In order to exercise all rights, the data subject may
        submit an appropriate request by contacting the Controller in the
        following ways:
        <ul style="list-style-type: disc">
          <li>
            by mail at the address: <b>Via Po 41 - 20015 Parabiago (MI)</b>;
          </li>
          <li>
            by sending an e-mail to the ordinary e-mail address
            <a href="mailto:commerciale@kiefervonzoe.com"
              >commerciale@kiefervonzoe.com</a
            >
          </li>
        </ul>
      </li>
      <li class="list my-3">
        CHANGES<br />
        This privacy policy was updated on 14 June 2022<br class="my-3" />The
        Company reserves the right to partially or fully modify this policy or
        update its content, for example, as a result of changes in applicable
        law. Therefore, the Company invites the User to regularly consult this
        Policy in order to be aware of the latest version, so that they are
        always informed about the way in which Personal Data is collected and
        used.
      </li>
    </ol>
  </div>
</template>
<style scoped lang="scss">
.privacy {
  color: var(--primary-color);
  line-height: 1.5;
}
.col {
  border: 1px solid var(--primary-color);
  padding: 1rem 0.5rem;
}
</style>
