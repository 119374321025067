export const paragraphAboutUs = {
  title: {
    EN: "Kiefer von Zoe® is a constantly-evolving hub in which research, innovation and design are combined to come up with cutting-edge, sustainable, yet highly performing materials for the fashion industry and beyond.",
    IT: "Kiefer von Zoe® è un hub in continua evoluzione dove uniamo ricerca, innovazione e design per concepire materiali tecnici, sostenibili e all’avanguardia per il mercato della moda."
  },
  body: {
    EN: "Our mission is to create and manufacture the most advanced fashion and design materials possible, leaving the world better than we found it.",
    IT: "Il nostro scopo è quello di progettare e realizzare i materiali più performanti al mondo per i settori fashion e design, lasciando alle generazioni future un mondo migliore di quello che abbiamo ereditato."
  }
};
export const videoOverlay = {
  EN: "KIEFER VON ZOE® - JOIN THE (R)EVOLUTION",
  IT: "KIEFER VON ZOE® - JOIN THE (R)EVOLUTION"
};
export const history = {
  EN: "Our history",
  IT: "La nostra storia"
};

export const carousel = [
  {
    id: 0,
    year: 2007,
    images: [
      {
        img: "KPB",
        EN: "KPB",
        IT: "KPB"
      }
    ]
  },
  {
    id: 1,
    year: 2009,
    images: [
      {
        img: "LRC",
        EN: "LRC",
        IT: "LRC"
      }
    ]
  },
  {
    id: 2,
    year: 2013,
    images: [
      {
        img: "LRCS",
        EN: "LRCS",
        IT: "LRCS"
      }
    ]
  },
  // {
  //   id: 3,

  //   year: 2014,
  //   images: [
  //     {
  //       img: "PK",
  //       EN: "PK",
  //       IT: "PK"
  //     }
  //   ]
  // },
  {
    id: 3,

    year: 2015,
    images: [
      {
        img: "NPU_GREEN",
        EN: "NPU Green",
        IT: "NPU Green"
      },
      {
        img: "SCARPA_SENZA_LAMINA",
        EN: "Shoe with NPU Green insole without steel shank",
        IT: "Scarpa con soletta realizzata in NPU Green senza lamina in acciaio"
      }
    ]
  },

  {
    id: 4,

    year: 2019,
    images: [
      {
        img: "BRL",
        EN: "BRL",
        IT: "BRL"
      },
      {
        img: "SOLETTE_BRL",
        EN: "Insole with comfort footbed in BRL",
        IT: "Solette con pianta comfort realizzata in BRL"
      },
      {
        img: "SQN",
        EN: "SQN",
        IT: "SQN"
      }
    ]
  },

  {
    id: 5,
    year: 2021,
    images: [
      {
        img: "TELE",
        EN: "PANAMA <br/> BELIZE <br/> NICARAGUA<br/>Reinforcement fabrics",
        IT: "PANAMA <br/> BELIZE <br/> NICARAGUA<br/>Tele da rinforzo"
      },
      {
        img: "BIO",
        EN: "BIO<br/>The first Kiefer von Zoe® material for toe puffs and counters",
        IT: "BIO<br/>Il primo materiale Kiefer von Zoe® per puntali e contrafforti"
      }
      // {
      //   img: "KVZ_CONSULTING",
      //   EN: "Launch of the Consulting division",
      //   IT: "Lancio della divisione Consulting"
      // }
    ]
  },

  {
    id: 6,
    year: 2022,
    images: [
      // {
      //   img: "KR1",
      //   EN: "KR1",
      //   IT: "KR1"
      // },
      {
        img: "HEVEA",
        EN: "HEVEA",
        IT: "HEVEA"
      },
      {
        img: "EUCALYPTUS",
        EN: "EUCALYPTUS STRONG and EUCALYPTUS SOFT",
        IT: "EUCALYPTUS STRONG e EUCALYPTUS SOFT"
      },
      {
        img: "PIUMA_NUVOLA",
        EN: "PIUMA and NUVOLA",
        IT: "PIUMA e NUVOLA"
      }
    ]
  },
  {
    id: 7,
    year: 2023,
    images: [
      {
        img: "HOLLAND",
        EN: "HOLLAND",
        IT: "HOLLAND"
      },
      {
        img: "VIDARR",
        EN: "VIÐARR",
        IT: "VIÐARR"
      }
    ]
  }
];
