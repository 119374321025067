<template>
  <div class="kvz-materials-container card-wrapper">
    <kvz-card
      v-for="material in materials"
      :id="material.path"
      :key="material.path"
      :img="material.img"
      :recycle="material.recycle"
      :is-png="true"
      :label="material[selectedLanguage]"
      @openContext="openContext"
    />
  </div>
</template>
<script>
import { materials } from "@/crm/materials";
export default {
  data() {
    return {
      materials: materials
    };
  },
  computed: {
    selectedLanguage() {
      return this.$store.state.values.language;
    }
  },
  methods: {
    openContext(id) {
      this.$router.push({
        name: "materialsContext",
        params: { context: id, parent: " " }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/_function.scss";
.kvz-materials-container {
  padding: toRem(95) 0;
  margin: 0 auto;
}
.card-wrapper {
  margin: 0 auto;
  display: grid;
  gap: toRem(69) toRem(22);
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 860px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
