<template>
  <div>
    <div v-for="iso in isoData" :key="iso.name" class="bar">
      <div class="bar-name">{{ iso.name }}</div>
      <!-- <progress :value="iso.percentage" max="100" /> -->
      <div class="bar-data">
        <div class="progressbar" :style="`max-width:${iso.percentage}`" />
        <div class="percentage">
          {{ iso.percentage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isoData: [
        { name: "ISO 14001", percentage: "80%" },
        { name: "ISO 9001", percentage: "70%" },
        { name: "ISO 45001", percentage: "40%" },
        { name: "ISO 50001", percentage: "30%" }
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/function";
@import "@/scss/color";
.bar {
  width: 100%;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: toRem(9) 0;
  &-name {
    width: toRem(110);
    font-style: normal;
    font-weight: 700;
    font-size: toRem(16);
    line-height: toRem(18);

    color: var(--primary-color);
  }
  &-data {
    width: 100%;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: toRem(20);
    .progressbar {
      height: toRem(20);
      margin-right: toRem(6);
      background-color: $green;
      animation: 0.8s width forwards;
    }
    .percentage {
      font-style: normal;
      font-weight: 700;
      font-size: toRem(16);
      line-height: toRem(18);

      color: var(--primary-color);
    }
  }
}

@keyframes width {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
</style>
