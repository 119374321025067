<template>
  <div id="accordionMaterial" class="accordion">
    <div class="accordion-item">
      <h2 id="performance" class="accordion-header">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseAccordion"
          aria-expanded="true"
          aria-controls="collapseAccordion"
        >
          {{ data[selectedLanguage] }}
        </button>
      </h2>

      <div
        id="collapseAccordion"
        class="accordion-collapse collapse"
        aria-labelledby="performance"
        data-bs-parent="#accordionMaterial"
      >
        <div class="accordion-body">
          <ul>
            <li v-for="(point, key) in data.data" :key="key">
              {{ point[selectedLanguage] }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  computed: {
    selectedLanguage() {
      return this.$store.state.values.language;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/function";
@import "@/scss/color";
.accordion-button:not(.collapsed)::after {
  display: none;
}
.accordion-button:not(.collapsed)::before {
  background-image: url("@/assets/plus.svg");
  transform: rotate(45deg);
}
.accordion-button::after {
  display: none;
}
.accordion-button::before {
  flex-shrink: 0;
  width: toRem(16);
  height: toRem(16);
  margin-left: 0;
  margin-right: toRem(10);
  content: "";
  background-image: url("@/assets/plus.svg");
  background-repeat: no-repeat;
  background-size: toRem(16);
  transition: var(--bs-accordion-btn-icon-transition);
}
.accordion-button {
  box-shadow: none;
  border: none;
}
.accordion {
}
.accordion-item {
  border: none;
  border-left: 4px solid $blue;
  border-radius: 0;
  * {
    background: #f8f8f8;
    border-radius: 0;
  }
  .accordion-button {
    color: $black;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 700;
    font-size: toRem(20);
    line-height: toRem(24);
  }
  .accordion-body {
    font-style: normal;
    font-weight: 400;
    font-size: toRem(15);
    line-height: toRem(24);
    color: $black;
    ul {
      list-style: none;
    }
  }
}
</style>
