<template>
  <div>
    <footer class="text-center text-lg-start text-muted footer">
      <section>
        <div class="container text-center text-md-start">
          <div class="row">
            <!-- Grid column -->
            <div class="col-md-4 col-lg-2 col-xl-2 mx-auto mb-md-0 mb-4">
              <!-- Content -->
              <h6 class="text-uppercase fw-bold mb-4">
                {{ socialList.title[selectedLanguage] }}
              </h6>
              <div class="d-flex flex-column">
                <a
                  v-for="(social, index) in socialList.items"
                  :key="index"
                  :href="social.link"
                  target="_blank"
                >
                  {{ social.label }}
                </a>
              </div>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-4 col-lg-7 col-xl-7 mx-auto mb-md-0 mb-4">
              <!-- Links -->
              <h6 class="text-uppercase fw-bold mb-4">
                {{ footerContacts.title[selectedLanguage] }}
              </h6>
              <p v-for="(contact, index) in footerContacts.items" :key="index">
                <span v-if="!contact.mailto">
                  {{ contact[selectedLanguage] }}</span
                >
                <a
                  v-if="contact.mailto"
                  :href="`mailto:${contact[selectedLanguage]}`"
                >
                  {{ contact[selectedLanguage] }}</a
                >
              </p>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0">
              <!-- Links -->
              <h6 class="text-uppercase fw-bold mb-4">
                {{ footerCompanyData.title[selectedLanguage] }}
              </h6>
              <p
                v-for="(data, index) in footerCompanyData.items"
                :key="index"
                v-html="data[selectedLanguage]"
              />
            </div>
            <!-- Grid column -->
          </div>
          <!-- Grid row -->
        </div>
      </section>
    </footer>
    <div class="privacy d-flex justify-content-center py-3">
      Copyright 2022 Kiefer von Zoe® Srl - All Rights Reserved |&nbsp;
      <router-link :to="{ name: 'cookie' }" target="_blank">
        Cookie Policy </router-link
      >&nbsp; |&nbsp;
      <router-link :to="{ name: 'privacy' }" target="_blank">
        Privacy Policy
      </router-link>
    </div>
  </div>
</template>
<script>
import { footerCompanyData, footerContacts } from "@/crm/footer";
import { socialList } from "@/crm/common";
export default {
  data() {
    return {
      footerCompanyData: footerCompanyData,
      socialList: socialList,
      footerContacts: footerContacts
    };
  },
  computed: {
    selectedLanguage() {
      return this.$store.state.values.language;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/_function.scss";
@import "@/scss/_color.scss";
.privacy {
  background: $grey-light;
  border-top: 1px solid $black-light;
  color: $black-light;
  font-weight: 400;
  font-size: toRem(14);
  line-height: toRem(17);
}
.footer {
  padding: toRem(65) 0 toRem(70);
  background: $grey-light;
  h6 {
    color: $black-light;
    font-style: normal;
    font-weight: 700;
    font-size: toRem(16);
    line-height: toRem(19);
    letter-spacing: toRem(2);
    position: relative;
    @media screen and (max-width: 767px) {
      width: fit-content;
      margin: auto;
    }
    &::after {
      content: "";
      position: absolute;
      border-top: 2px solid var(--blue-color);
      left: 0;
      bottom: -4px;
      width: toRem(24);
      height: 0px;
    }
  }
  a,
  p {
    color: $black-light;
    font-style: normal;
    font-weight: 400;
    font-size: toRem(14);
    line-height: toRem(17);
    margin-top: toRem(12);
    text-decoration: none;
  }
  a:hover {
    font-weight: 500;
  }
}
</style>
