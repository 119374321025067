<template>
  <div>
    <div v-if="!$route.params?.child" class="toggle-theme" @click="toggleTheme">
      <img v-if="lightModeOn" src="@/assets/dark.png" alt="" />
      <img v-else src="@/assets/light.png" alt="" />
    </div>
    <div v-else class="toggle-theme" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      userTheme: localStorage.getItem("user-theme") || "light-theme",
      forceLightMode: false
    };
  },
  computed: {
    lightModeOn() {
      return this.userTheme === "light-theme";
    }
  },
  watch: {
    $route(val) {
      if (val.params?.child) {
        //material detail
        this.getMediaPreference({ forceLightMode: true });
        this.setTheme("light-theme", true);
        this.forceLightMode = true;
      } else {
        this.getMediaPreference();
        const prevTheme = this.getTheme();
        this.setTheme(prevTheme);
        this.forceLightMode = false;
      }
    }
  },
  mounted() {
    if (this.$route.params?.child) {
      this.setTheme("light-theme");
      return;
    }
    const initUserTheme = this.getTheme() || this.getMediaPreference();
    this.setTheme(initUserTheme);
  },

  methods: {
    toggleTheme() {
      if (!this.forceLightMode) {
        const activeTheme = localStorage.getItem("user-theme");
        if (activeTheme === "light-theme") {
          this.setTheme("dark-theme");
        } else {
          this.setTheme("light-theme");
        }
      }
    },

    getTheme() {
      return localStorage.getItem("user-theme");
    },

    setTheme(theme, extra) {
      if (!extra) {
        localStorage.setItem("user-theme", theme);
      }

      this.$store.dispatch("values/change-theme", theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
    },

    getMediaPreference(data) {
      if (data?.forceLightMode) {
        return "light-theme";
      }
      const hasDarkPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (hasDarkPreference) {
        return "dark-theme";
      } else {
        return "light-theme";
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.toggle-theme {
  cursor: pointer;
  width: 35px;
}
</style>
