<template>
  <div
    class="kvz-wrapper d-flex flex-column justify-content-between"
    :style="$route.name === 'cookie' ? 'background-color:#ffffff' : ''"
  >
    <div>
      <kvz-navbar v-if="isVisible" />
      <div v-else class="theme-single">
        <kvz-theme-button v-if="$route.name !== 'cookie'" />
      </div>
      <router-view v-slot="{ Component }">
        <transition>
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <kvz-footer v-if="isVisible" />
  </div>
</template>
<script>
export default {
  computed: {
    isVisible() {
      return (
        this.$route.name !== "cookie" &&
        this.$route.name !== "privacy" &&
        this.$route.name !== "info"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.kvz-wrapper {
  min-height: 100vh;
}
.v-enter-active {
  transition: opacity 0.8s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.theme-single {
  position: absolute;
  top: 2rem;
  right: 2rem;
}
</style>
