export const footerContacts = {
  title: {
    EN: "Contacts",
    IT: "Contatti"
  },
  items: [
    {
      EN: "(+39) 0331 422868",
      IT: "(+39) 0331 422868"
    },
    {
      EN: "support@kiefervonzoe.com",
      IT: "support@kiefervonzoe.com",
      mailto: true
    },
    {
      EN: "Via Po, 41 - 20015 Parabiago (MI) - Italy",
      IT: "Via Po, 41 - 20015 Parabiago (MI) - Italia"
    }
  ]
};
export const footerCompanyData = {
  title: {
    EN: "kiefer von zoe® srl",
    IT: "kiefer von zoe® srl"
  },
  items: [
    {
      EN: "Kiefer von Zoe Srl - REA Milano n. MI-1835734 | Iscriz. Reg. Impr. Milano n. 05630890969",
      IT: "Kiefer von Zoe Srl - REA Milano n. MI-1835734 | Iscriz. Reg. Impr. Milano n. 05630890969"
    },
    {
      EN: "C.F e P. IVA IT05630890969 ",
      IT: "C.F e P. IVA IT05630890969 "
    },
    {
      EN: "Share capital entirely paid in € 10.000,00",
      IT: "Capitale Sociale € 10.000,00 <br/>Interamente Versato"
    }
  ]
};
