<template>
  <div>MATERAL</div>
</template>
<script>
export default {
  props: {
    dataSheet: {
      type: Object,
      default: () => {},
      required: true
    }
  }
};
</script>
