export const contacts = {
  EN: "contacts",
  IT: "contatti",
  data: [
    {
      EN: "phone",
      IT: "telefono",
      value: "(+39) 0331 422868"
    },
    {
      EN: "e-mail",
      IT: "indirizzo Mail",
      value: "support@kiefervonzoe.com"
    },

    {
      EN: "address",
      IT: "indirizzo",
      value: "via Po, 41 - 20015 Parabiago (MI) - Italy"
    },
    {
      EN: "relevant info",
      IT: "informazioni utili",
      doc: {
        EN: "Information Ex Art. 1C 125 L.124/2017",
        IT: "Informazioni Ex Art. 1C 125 L.124/2017"
      },
      linkTo: "info"
    }
  ]
};
export const address = {
  EN: "address",
  IT: "indirizzo",
  address: "via Po, 41 - 20015 Parabiago (MI) - Italy",
  table: [
    {
      location: {
        EN: "Kiefer von Zoe® Hq - Milan",
        IT: "Kiefer von Zoe® Hq - Milano"
      },
      distance: "30 min"
    },
    {
      location: {
        EN: "Kiefer von Zoe® Hq - Milan Malpensa airport (MXP)",
        IT: "Kiefer von Zoe® Hq - Aereoporto Milano Malpensa (MXP)"
      },
      distance: "30 min"
    },
    {
      location: {
        EN: "Kiefer von Zoe® Hq - Milan Linate airport (LIN)",
        IT: "Kiefer von Zoe® Hq - Aereoporto Milano Linate (LIN)"
      },
      distance: "45 min"
    },
    {
      location: {
        EN: "Kiefer von Zoe® Hq - Milan Orio al Serio airport (BGY)",
        IT: "Kiefer von Zoe® Hq - Aereoporto Milano Orio al Serio (BGY)"
      },
      distance: "50 min"
    }
  ]
};
