<template>
  <div class="row">
    <div class="col-sm-12 col-md-2">
      <img
        :src="helper.getImage(article.coverImage)"
        alt=""
        class="coverImage"
      />
    </div>
    <div class="col-sm-12 col-md-8 article">
      <div>
        <h4 class="article--title">{{ article.title[selectedLanguage] }}</h4>
        <div class="article--subtitle">
          {{ article.subtitle[selectedLanguage] }}
        </div>
      </div>
      <div class="article--pubblication">
        {{ article.pupblicationDate[selectedLanguage] }}
      </div>
    </div>
    <router-link
      :to="{ name: 'article', params: { id: article.parseTitle } }"
      class="col-sm-12 col-md-2 goTo"
    >
      {{ linklabel[selectedLanguage] }}
      <svg viewBox="0 0 24 24">
        <use href="@/assets/goTo.svg#goTo" />
      </svg>
    </router-link>
  </div>
</template>
<script>
import * as helper from "@/helper/helper";
export default {
  props: {
    article: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data() {
    return {
      helper: helper,
      linklabel: {
        EN: "learn more",
        IT: "scopri di più"
      }
    };
  },
  computed: {
    selectedLanguage() {
      return this.$store.state.values.language;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/function";
.row {
  border-top: 1px solid var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
  padding: toRem(24) 0;
  display: flex;
  justify-content: space-between;
  > * {
    padding: 0;
  }
}
.coverImage {
  height: toRem(105);
  width: -webkit-fill-available;
  padding: 0 toRem(23) 0 0;
  object-fit: cover;
  @media screen and (max-width: 767px) {
    padding: 0;
  }
}
.article {
  //padding-left: toRem(23);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  &--title {
    font-style: normal;
    font-weight: 700;
    font-size: toRem(28);
    line-height: toRem(32);
    color: var(--primary-color);
    margin: 0;
  }
  &--subtitle {
    font-style: normal;
    font-weight: 300;
    font-size: toRem(16);
    line-height: 1.5;
    color: var(--primary-color);
    margin-top: 5px;
  }
  &--pubblication {
    font-style: normal;
    font-weight: 700;
    font-size: toRem(13);
    line-height: toRem(15);
    letter-spacing: 2px;
    color: var(--blue-color);
  }
}
.goTo {
  text-align: end;
  margin: auto;

  font-style: normal;
  font-weight: 600;
  font-size: toRem(18);
  line-height: toRem(19);
  text-align: right;
  color: var(--primary-color);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-decoration-color: var(--blue-color);
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;
  }
  svg {
    margin-left: toRem(12);
    height: toRem(15);
    width: toRem(15);
    stroke: var(--primary-color);
  }
}
</style>
