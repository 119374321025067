export const sustainability = [
  {
    image: "1_FSC",
    year: "2007",
    title: {
      IT: "MATERIA PRIMA CERTIFICATA FSC",
      EN: "CERTIFIED RAW MATERIAL"
    },
    text: {
      IT: "Da sempre, per la produzione dei nostri fibrati utilizziamo esclusivamente cellulosa proveniente da foreste gestite responsabilmente.",
      EN: "We have always been using only cellulose from responsibly managed forests for the production of our fibreboards."
    }
  },
  {
    image: "2_BIODEGRADABILITA_E_IMPIANTI",
    year: "2007",
    title: {
      IT: "SOSTENIBILITÀ DEGLI IMPIANTI E BIODEGRADABILITÀ DEI MATERIALI",
      EN: "SUSTAINABILITY OF PLANTS AND BIODEGRADABILITY OF MATERIALS"
    },
    text: {
      IT: "La sostenibilità è in ogni suo ambito parte integrante della nostra cultura e filosofia aziendale. Non solo i materiali Kiefer von Zoe® soddisfano rigorosi criteri ambientali, ma ci preoccupiamo che anche i nostri stabilimenti produttivi e i fornitori a cui scegliamo di appoggiarci condividano questo stesso impegno. Per noi è, inoltre, fondamentale che i materiali Kiefer von Zoe® abbiano un basso impatto ambientale anche in fase di alienazione. In linea con questo obiettivo, la divisione R&D, da sempre, si concentra sullo sviluppo di fibrati e materiali da rinforzo biodegradabili.",
      EN: "Sustainability is a core element of our corporate culture and philosophy. Not only do Kiefer von Zoe® materials meet strict environmental criteria, we also make sure that our production facilities and the suppliers we engage with share this very same commitment. It is also crucial that Kiefer von Zoe® materials have a low environmental impact as far as its disposal is concerned. In line with this objective, the R&D department has always been focused on the development of biodegradable fibreboards and reinforcement materials."
    },
    extra: {
      text: {
        IT: "A proposito degli nostri stabilimenti produttivi, siamo fieri di poter affermare che l’80% dei nostri è certificato con riferimento allo standard ISO 14001 (Sistemi di Gestione Ambientale), il 70% secondo lo standard ISO 9001 (Sistemi di Gestione della Qualità), il 40% in riferimento allo standard ISO 45001 (Sistemi di Gestione per la Salute e la Sicurezza dei Lavoratori - ex OHSAS 18001) e il 30% secondo lo standard ISO 50001 (Sistemi di Gestione per l’Energia).",
        EN: "As for our production facilities, we are proud to state that 80% are certified to ISO 14001 (Environmental Management Systems), 70% to ISO 9001 (Quality Management Systems), 40% to ISO 45001 (Occupational Health and Safety Management Systems - formerly OHSAS 18001) and 30% to ISO 50001 (Energy Management Systems)."
      },
      graph: "kvz-iso-graph"
    }
  },
  {
    image: "3_ANALISI",
    year: "2017",
    title: {
      IT: "ANALISI SU LOTTI PRODOTTI ",
      EN: "ANALYSIS OF PRODUCED BATCHES"
    },
    text: {
      IT: "Per attestare la naturalità dei nostri materiali, svolgiamo sistematicamente analisi sui lotti prodotti.",
      EN: "To certify that our materials are environment-friendly, we systematically carry out analyses on the batches produced."
    }
  },
  {
    image: "4_CoC",
    year: "2020",
    title: {
      IT: "CERTIFICAZIONE DELLA CATENA DI CUSTODIA FSC (CoC)",
      EN: "FSC CHAIN OF CUSTODY CERTIFICATION (CoC)"
    },
    text: {
      IT: "Kiefer von Zoe® ha ottenuto la certificazione per la Catena di Custodia (CoC) FSC, sia per la cellulosa che per il lattice naturale.",
      EN: "Kiefer von Zoe® has obtained FSC Chain of Custody (CoC) certification for both cellulose and natural latex."
    },
    extra: {
      text: {
        IT: "Cerca i nostri prodotti certificati FSC®",
        EN: "Find out our FSC® certified products"
      },
      image: "FSC_C159192"
    }
  },
  {
    image: "5_CONSULTING",
    year: "2021",
    title: {
      IT: "KVZ CONSULTING",
      EN: "KVZ CONSULTING"
    },
    text: {
      IT: "Dal 2021, con il lancio di una divisione aziendale dedicata, Kiefer von Zoe® mette a disposizione dei Brand l’esperienza maturata dai suoi manager nel settore calzaturiero. KvZ Consulting offre - nell’ambito dei componenti interni per calzature - un’assistenza tailor made a partire dalle specifiche esigenze del cliente e un servizio di project management completo, dalla definizione degli obiettivi di prestazione e sostenibilità, fino alla realizzazione di prototipi e campionature.",
      EN: "Since 2021, with the launch of a dedicated business division, Kiefer von Zoe® has been making the experience gained by its managers in the shoe industry available to brands. When it comes to inner shoe components, KvZ Consulting offers tailor-made support based on specific customer requirements and a comprehensive project management service, from the definition of performance and sustainability targets to the realisation of prototypes and samples."
    }
  },
  {
    image: "6_BORRACCIA",
    year: 2021,
    title: {
      IT: "BORRACCIA KVZ",
      EN: "KVZ BOTTLE"
    },
    text: {
      IT: "Dal 2021, in linea con l'obbiettivo di ridurre costantemente la quantità di plastica presente all'interno della nostra organizzazione, abbiamo eliminato definitivamente le bottigliette usa e getta, dotando l'intero team Kiefer von Zoe® di borracce. Ogni volta che riempiamo una di queste borracce, evitiamo l'immissione di -0,08 Kg di CO2 EQ nell'atmosfera - la stessa quantità di CO2 emessa nella produzione di una bottiglietta di plastica usa e getta (*).",
      EN: "In line with our goal of constantly reducing the amount of plastic in our organization, since 2021 we have completely eliminated disposable bottles, equipping the entire Kiefer von Zoe® team with thermal bottles. Every time we fill one of these water bottles, we avoid the emission of -0.08 kg of CO2 EQ into the atmosphere - the same amount of CO2 emitted in the production of a disposable plastic bottle (*)."
    },

    source: {
      IT: "*Fonte: 24bottles",
      EN: "*Source: 24bottles"
    }
  },
  {
    image: "7_BEE_KIEFER",
    year: "2022-2023",
    title: {
      IT: "BEE KIEFER",
      EN: "BEE KIEFER"
    },
    text: {
      IT: "Da oltre 15 anni ci impegniamo a innovare in modo sostenibile, a preservare la biodiversità e a creare un impatto sociale positivo, restituendo alla natura le sue ricchezze attraverso un approvvigionamento sostenibile delle materie prime. Nel 2022, questo impegno – animato dal senso di dovere che ci spinge ad agire per consegnare alle generazioni future un mondo migliore di quello che abbiamo ereditato – ha raggiunto una nuova frontiera con il lancio del progetto “BEE KIEFER”. La naturale evoluzione del nostro percorso coincide, infatti, con la decisione di iniziare a dedicarci alla protezione e preservazione di uno dei tesori più preziosi della natura: le api.",
      EN: "For over 15 years, we have been committed to sustainable innovation, preserving biodiversity and creating a positive social impact by giving back to nature its treasures through sustainable sourcing of raw materials. Driven by a sense of duty to deliver to future generations a better world than the one we inherited, this commitment has reached in 2022 a new frontier with the launch of our 'BEE KIEFER' project. The natural evolution of our path, in fact, coincides with the decision to start dedicating ourselves to the protection and preservation of one of nature's most precious treasures: bees."
    },
    linkTo: "latest"
  }
];
