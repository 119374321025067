import { createStore } from "vuex";

import values from "./modules/values";

const store = createStore({
  modules: {
    values
  }
});

export default store;
