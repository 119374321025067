<template>
  <router-link
    :to="{ name: name, params: params }"
    :class="['backTo', { center }]"
  >
    <img src="@/assets/back.png" alt="" />
    <span class="d-none d-lg-block">{{ label }}</span>
  </router-link>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: "home-page"
    },
    params: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: "Back to homepage"
    },
    center: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/function";
@import "@/scss/color";
.backTo {
  position: absolute;
  left: 5%;
  top: toRem(35);
  &.center {
    top: 50%;
    transform: translate(0, -50%);
    @media (max-width: 710px) {
      top: -0.5rem !important;
    }
  }
  background: $white;
  padding: toRem(5) toRem(16);

  border-radius: toRem(26);
  font-style: normal;
  font-weight: 400;
  font-size: toRem(14);
  line-height: toRem(20);
  color: $black;
  text-decoration: none;
  display: flex;
  align-items: flex-start;

  img {
    margin-right: toRem(10);
  }
}
</style>
