<template>
  <div class="kvz-card" @click="openContext">
    <div class="image">
      <div class="mask">
        <!-- <kvz-recycle v-if="recycle" class="mb-3 recycle" /> -->
        <span v-if="recycle" class="recycle"> {{ recycleLabel }}</span>
      </div>
      <img :src="helper.getImage(img, isPng)" loading="lazy" :alt="label" />
    </div>
    <span class="description mt-3 d-flex"> {{ label }} </span>
  </div>
</template>
<script>
import * as helper from "@/helper/helper";
export default {
  props: {
    id: {
      type: String,
      default: "material",
      required: true
    },
    img: {
      type: String,
      default: "",
      required: true
    },
    label: {
      type: String,
      default: ""
    },
    isPng: {
      type: Boolean,
      default: false
    },
    recycle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      helper: helper
    };
  },
  computed: {
    recycleLabel() {
      return this.$store.state.values.language == "EN"
        ? "Recycled material"
        : "Materiale riciclato";
    }
  },
  methods: {
    openContext() {
      this.$emit("openContext", this.id);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/_function.scss";
.kvz-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  &:hover {
    // #recycle-svg {
    //   fill: var(--blue-color);
    // }
    .recycle {
      display: flex;
    }
    .image {
      border-radius: toRem(24);
      .mask {
        background: linear-gradient(
          225deg,
          rgba(170, 187, 255, 0.48) 0%,
          rgba(255, 255, 255, 0.36) 100%
        );
      }
    }

    .description {
      font-weight: 600;
      color: var(--blue-color);
    }
  }
  .image {
    height: toRem(220);
    width: toRem(390);
    border-radius: 0.5rem;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    overflow: hidden;
    position: relative;

    img {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
    }
    .mask {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background: rgba(0, 0, 0, 0);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      font-weight: 700;
      color: var(--primary-color);
      font-style: normal;
      letter-spacing: 0.05rem;
      text-transform: uppercase;
      font-size: toRem(28);
      line-height: toRem(24);
    }
  }

  .description {
    font-style: normal;
    font-weight: 400;
    font-size: toRem(28);
    line-height: toRem(24);
    /* identical to box height, or 86% */

    text-align: center;
    text-transform: uppercase;

    color: var(--primary-color);
  }
}
.recycle {
  display: none;
}
</style>
