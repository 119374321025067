<template>
  <div id="cookie-container" class="container-fluid my-5 px-5 cookie" />
</template>
<!-- Script COOKIE - Dichiarazione dei cookie
Questo secondo script va inserito nell'HTML della pagina specifica in cui desiderate che appaia la Dichiarazione dei cookie.
LINGUA ITALIANO: -->
<!-- <script id="CookieDeclaration" src="https://consent.cookiebot.com/702b1b44-0ec2-463d-95f6-f782346cb089/cd.js" type="text/javascript" async></script> -->

<!-- LINGUA INGLESE: -->
<!-- <script id="CookieDeclaration" data-culture="EN" src="https://consent.cookiebot.com/702b1b44-0ec2-463d-95f6-f782346cb089/cd.js" type="text/javascript" async></script> -->

<script>
export default {
  data() {
    return {
      scriptLink: {
        EN: "https://consent.cookiebot.com/702b1b44-0ec2-463d-95f6-f782346cb089/cd.js",
        IT: "https://consent.cookiebot.com/702b1b44-0ec2-463d-95f6-f782346cb089/cd.js"
      }
    };
  },
  // computed: {
  //   selectedLanguage() {
  //     return this.$store.state.values.language;
  //   }
  // },

  async mounted() {
    let externalScript = document.createElement("script");
    const language = localStorage.getItem("language");
    externalScript.setAttribute(
      "src",
      language ? this.scriptLink[language] : this.scriptLink["EN"]
    );
    externalScript.setAttribute("id", "CookieDeclaration");
    externalScript.setAttribute("type", "text/javascript");
    externalScript.setAttribute("async", "");
    if (language === "EN") {
      externalScript.setAttribute("data-culture", "EN");
    }

    document.getElementById("cookie-container").appendChild(externalScript);
    //document.head.appendChild(externalScript);
  }
};
</script>
<style lang="scss" scoped>
.cookie {
  color: #222222;
}
</style>
