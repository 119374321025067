export const NPU_GREEN = {
  image: "NPU_GREEN",
  title: "NPU GREEN",
  description: {
    EN: "Highlights",
    IT: "Descrizione",
    data: [
      {
        IT: `NPU Green è un fibrato innovativo di altissima qualità.<br/>
            Prodotto con fibre di pura cellulosa vergine proveniente da foreste gestite
            responsabilmente, NPU Green conferisce alla soletta una rigidità e una
            resistenza torsionale estremamente elevate, superiori a quelle di qualsiasi
            altro materiale a base cellulosica.`,
        EN: `NPU Green is a high-quality, innovative fibreboard. <br/>
            Made of pure cellulose  fibre coming from responsibly managed forests , NPU Green gives 
            insoles advanced stiffness and exceptional nail puncture resistance, outperforming any other 
            cellulose-based material.`
      },
      {
        IT: `<i>NPU Green non produce fuliggine durante la laseratura, permettendo
            maggiore pulizia e tempi di lavorazione più brevi.</i>`,
        EN: "<i>NPU Green doesn’t produce any soot when worked with lasers, guaranteeing greater cleanliness and shorter processing times.</i>"
      }
    ]
  },
  performace: {
    EN: "Performance",
    IT: "Performance",
    data: [
      {
        IT: `• Rigidità elevata: molto superiore a quella dei comuni fibrati`,
        EN: `• Stiffness that’s far superior to common fibreboards`
      },
      {
        IT: `• Possibilità di produrre solette senza lamina**`,
        EN: "• Insoles can also be made without a steel shank **"
      },
      {
        IT: `• Tenuta al chiodo eccellente`,
        EN: `• Excellent nail retention`
      },
      {
        IT: `• Modellabilità eccellente `,
        EN: "• Excellent modelling"
      },
      {
        IT: `• Prestazioni elevate nel tempo (lunga vita utile)`,
        EN: `• Great performance over time (long life span) `
      },
      {
        IT: `• Leggerezza`,
        EN: `• Lightness`
      }
    ]
  },

  detail: [
    {
      primary: {
        IT: `COLORE`,
        EN: `COLOR`
      },
      secondary: {
        IT: `bianco*`,
        EN: `white*`
      }
    },
    {
      primary: {
        IT: `MARCATURA`,
        EN: `BRANDING`
      },
      secondary: {
        IT: `Kiefer Von Zoe`,
        EN: `Kiefer Von Zoe`
      }
    },
    {
      primary: {
        IT: `SPESSORI DISPONIBILI`,
        EN: `AVAILABLE THICKNESSES`
      },
      secondary: {
        IT: `1 | 1,25 | 2 | 2,5 | 3 mm`,
        EN: `1 | 1.25 | 2 | 2.5 | 3 mm`
      }
    },
    {
      primary: {
        IT: `FORMATO 1 | 1,25 | 2 mm`,
        EN: `SIZE 1 | 1.25 | 2 mm`
      },
      secondary: {
        IT: `1210x1540 mm`,
        EN: `1210x1540 mm`
      }
    },
    {
      primary: {
        IT: `FORMATO 2,5 | 3 mm`,
        EN: `SIZE 2.5 | 3 mm`
      },
      secondary: {
        IT: `1050x1200 mm`,
        EN: `1050x1200 mm`
      }
    },
    {
      primary: {
        IT: `BOBINE SU RICHIESTA`,
        EN: `REELS UPON REQUEST`
      }
    }
  ],
  extra: [
    {
      EN: `*Being a 100% natural material, the colouring may vary slightly.`,
      IT: `*trattandosi di un materiale 100% naturale, la
    colorazione potrebbe subire alcune variazioni.`
    },
    {
      EN: `** According to the tests carried out for the production of standard insoles applied to court shoe style footwear with a heel height of up to a 100 mm (4”), conducted according to a specific production technique developed by Kiefer Von Zoe® at its insole manufacturing facility of reference. 
    The following conditions apply to the industrial or artisanal production of footwear of any kind involving the use of assembly insoles made of NPU Green without steel shank:
    The use of such insoles without a steel shank was tested on standard models manufactured according to the specific technical procedure developed by Kiefer von Zoe® and may not be suitable for all types of footwear. Therefore, it is the shoe maker/insole manufacturer's responsibility to carry out the necessary resistance tests and to always test the stability of any structure in which they intend to install NPU Green insoles without a shank in order to verify the safety and suitability of the solution.
    The production of insoles without steel shank using NPU Green requires a precise technical procedure to be carried out at all stages correctly and according to the instructions provided upon purchase. Improperly storing and/or processing NPU Green can compromise, often significantly, the strength of the shank-free insole.
    Kiefer von Zoe® srl is not liable for the actual stability of insoles made of NPU Green without a steel shank and the footwear in which they are mounted, and shall not be held liable in any way for direct or indirect damages resulting from the improper storage of NPU Green, failure to follow the operating instructions provided, and the lack of or incorrect performance of specific use tests, industrialisation or design.
    `,
      IT: `** secondo i test eseguiti per la produzione di solette standard montate su modello di scarpa tipo “décolleté” fino a un tacco 100 max
    e realizzate secondo specifico procedimento tecnico di produzione messo a punto da Kiefer Von Zoe® presso il proprio solettificio di
    riferimento. Per la produzione industriale o artigianale di calzature di qualsiasi genere con l’utilizzo di solette di montaggio realizzate in
    NPU Green senza lamina in acciaio valgono le seguenti condizioni:
    L’utilizzo di questo tipo di solette senza lamina è stato testato per modelli standard realizzati secondo lo specifico procedimento
    tecnico messo a punto da Kiefer von Zoe® e potrebbe non essere adatto a tutti i tipi di calzatura. Pertanto è onere del calzaturificio/
    solettificio svolgere le opportune prove di resistenza e testare sempre la tenuta di ogni tipo di struttura in cui si intendano montare le
    solette senza lamina prodotte con NPU Green al fine di verificare la tenuta e la bontà della soluzione.
    La produzione di solette senza lamina con l’utilizzo di NPU Green richiede un preciso procedimento tecnico da eseguire in tutte le sue
    fasi in modo corretto e secondo le istruzioni che vengono fornite al momento dell’acquisto. Un’errata conservazione e/o lavorazione di
    NPU Green può compromettere, anche in modo serio, la resistenza della soletta senza lamina prodotta.
    Kiefer von Zoe® srl non si assume alcuna responsabilità sulla effettiva tenuta delle solette realizzate in NPU Green senza lamina in acciaio
    e delle calzature in cui vengono montate e non potrà essere ritenuta in nessun modo responsabile per danni diretti o indiretti derivanti
    da cattiva conservazione di NPU Green, negligenza nel seguire le istruzioni operative fornite, mancata o erronea esecuzione di specifici
    test d’uso, industrializzazione o progettazione.`
    }
  ],
  table: {
    line: 6,
    EN: "tabella_NPUGREEN_SQN_EN",
    IT: "tabella_NPUGREEN_SQN_IT"
  }
};
/********************************************************************************************************/
export const KPB = {
  image: "KPB",
  title: "KPB",
  description: {
    EN: "Highlights",
    IT: "Descrizione",
    data: [
      {
        IT: `
        KPB è un fibrato di altissima qualità.<br/>
        Prodotto con fibre di pura cellulosa vergine proveniente da foreste
        gestite responsabilmente, KPB conferisce alla soletta una rigidità e
        una resistenza torsionale estremamente elevate, superiori a quelle di
        qualsiasi materiale a base di cellulosa riciclata.`,
        EN: `KPB is a high-quality fibreboard. <br/>
        Made of pure cellulose fibres that come from responsibly managed forests, KPB gives insoles advanced stiffness and exceptional nail puncture resistance, outperforming any other recycled cellulose-based material.`
      },
      {
        IT: `<i>KPB non produce fuliggine durante la laseratura, permettendo maggiore
        pulizia e tempi di lavorazione più brevi.</i>`,
        EN: "<i>KPB doesn’t produce any soot when worked with lasers, guaranteeing greater cleanliness and shorter processing times.</i>"
      }
    ]
  },

  performace: {
    EN: "Performance",
    IT: "Performance",
    data: [
      {
        IT: `• Rigidità elevata: molto superiore a quella dei comuni fibrati`,
        EN: `• Stiffness that’s far superior to common fibreboards`
      },
      {
        IT: `• Tenuta al chiodo eccellente`,
        EN: "• Excellent nail retention"
      },
      {
        IT: `• Modellabilità eccellente`,
        EN: `• Excellent modelling`
      },
      {
        IT: `• Prestazioni elevate nel tempo (lunga vita utile) `,
        EN: "• Great performance over time (long life span)"
      },
      {
        IT: `• Leggerezza`,
        EN: `• Lightness `
      }
    ]
  },

  detail: [
    {
      primary: {
        IT: `COLORE`,
        EN: `COLOR`
      },
      secondary: {
        IT: `beige`,
        EN: `beige`
      }
    },
    {
      primary: {
        IT: `MARCATURA`,
        EN: `BRANDING`
      },
      secondary: {
        IT: `KPB - Kiefer Von Zoe`,
        EN: `KPB - Kiefer Von Zoe`
      }
    },
    {
      primary: {
        IT: `SPESSORI DISPONIBILI`,
        EN: `AVAILABLE THICKNESSES`
      },
      secondary: {
        IT: `0,8 | 1,25 | 2,5 | 3 mm`,
        EN: `0,8 | 1,25 | 2,5 | 3 mm`
      }
    },
    {
      primary: {
        IT: `FORMATO 0,8 | 1,25 mm`,
        EN: `SIZE 0,8 | 1,25 mm`
      },
      secondary: {
        IT: `1050x1540 mm`,
        EN: `1050x1540 mm`
      }
    },
    {
      primary: {
        IT: `FORMATO 2,5 | 3 mm`,
        EN: `SIZE 2,5 | 3 mm`
      },
      secondary: {
        IT: `1000x1000 mm`,
        EN: `1000x1000 mm`
      }
    }
  ],

  table: {
    line: 4,
    EN: "tabella_KPB_EN",
    IT: "tabella_KPB_IT"
  }
};
/********************************************************************************************************/
export const SQN = {
  image: "SQN",
  title: "SQN",
  description: {
    EN: "Highlights",
    IT: "Descrizione",
    data: [
      {
        IT: `
        SQN è un cellulosico innovativo di altissima qualità.<br/>
        Alternativa ai materiali comunemente usati per la parte flessibile della
soletta, è progettato specificamente per forme sfilate, molto appuntite
e per tutti gli articoli che richiedono spessore minimo e massima elasticità.`,
        EN: `SQN is a high-quality, innovative cellulose-based material.  <br/>
        An alternative to the cellulose-based materials commonly used for the flexible part of the insole, it's designed specifically for sleek, pointed toe shapes  and for all ultra-thin items that require maximum elasticity.`
      },
      {
        IT: `Prodotto con fibre di pura cellulosa vergine proveniente da foreste gestite responsabilmente, <i>SQN - se utilizzato insieme a NPU Green, KPB e/o KR1 - porta il grado di biodegradabilità della soletta prossimo al 100%.</i>`,
        EN: "<i>Combined with NPU Green, KPB and/or KR1 bring the degree of biodegradability  of the insole close to  100%.</i>"
      }
    ]
  },

  performace: {
    EN: "Performance",
    IT: "Performance",
    data: [
      {
        IT: `• Elasticità elevata: molto superiore a quella dei comuni cellulosici`,
        EN: `• Elasticity that’s far superior to common cellulose-based materials `
      },
      {
        IT: `• Spessore minimo`,
        EN: "• Extra thin"
      },
      {
        IT: `• Modellabilità eccellente`,
        EN: `• Excellent modelling`
      },
      {
        IT: `• Prestazioni elevate nel tempo (lunga vita utile) `,
        EN: "• Great performance over time (long life span)"
      },
      {
        IT: `• Leggerezza`,
        EN: `• Lightness `
      }
    ]
  },

  detail: [
    {
      primary: {
        IT: `COLORE`,
        EN: `COLOR`
      },
      secondary: {
        IT: `bianco`,
        EN: `white`
      }
    },
    {
      primary: {
        IT: `MARCATURA`,
        EN: `BRANDING`
      },
      secondary: {
        IT: `SQN - Kiefer Von Zoe`,
        EN: `SQN - Kiefer Von Zoe`
      }
    },
    {
      primary: {
        IT: `SPESSORE DISPONIBILE`,
        EN: `AVAILABLE THICKNESS`
      },
      secondary: {
        IT: `0.6 mm`,
        EN: `0.6 mm`
      }
    },
    {
      primary: {
        IT: `FORMATO`,
        EN: `SIZE`
      },
      secondary: {
        IT: `1500x1200 mm`,
        EN: `1500x1200 mm`
      }
    },
    {
      primary: {
        IT: `BOBINE SU RICHIESTA`,
        EN: `REELS UPON REQUEST `
      }
    }
  ],

  table: {
    line: 6,
    EN: "tabella_NPUGREEN_SQN_EN",
    IT: "tabella_NPUGREEN_SQN_IT"
  }
};
/********************************************************************************************************/
export const KR1 = {
  image: "KR1",
  title: "KR1",
  description: {
    EN: "Highlights",
    IT: "Descrizione",
    data: [
      {
        IT: `KR1 è un fibrato di alta qualità. <br/>KR1 è prodotto al 100% con fibre di cellulosa greggia al primo riciclo e proveniente da foreste gestite responsabilmente.
       `,
        EN: `KR1 is high-quality shankboard<br/>
        KR1 is made of 100% raw cellulose fibres from first-run recycling and coming from responsibly managed forests.`
      },
      {
        IT: `<i>KR1 non produce fuliggine durante la laseratura, permettendo maggiore
        pulizia e tempi di lavorazione più brevi.</i>`,
        EN: "<i>KR1 doesn’t produce any soot when worked with lasers, guaranteeing greater cleanliness and shorter processing times. </i>"
      }
    ]
  },

  performace: {
    EN: "Performance",
    IT: "Performance",
    data: [
      {
        IT: `• Rigidità elevata: superiore a quella dei comuni fibrati`,
        EN: `• Stiffness that’s superior to common fibreboards`
      },
      {
        IT: `• Tenuta al chiodo ottima`,
        EN: "• Excellent nail retention"
      },
      {
        IT: `• Modellabilità eccellente`,
        EN: `• Excellent modelling`
      },
      {
        IT: `• Prestazioni elevate nel tempo (lunga vita utile) `,
        EN: "• Great performance over time (long life span)"
      },
      {
        IT: `• Leggerezza`,
        EN: `• Lightness `
      }
    ]
  },

  detail: [
    {
      primary: {
        IT: `COLORE`,
        EN: `COLOR`
      },
      secondary: {
        IT: `beige`,
        EN: `beige`
      }
    },
    {
      primary: {
        IT: `MARCATURA`,
        EN: `BRANDING`
      },
      secondary: {
        IT: `KR1 - Kiefer Von Zoe`,
        EN: `KR1 - Kiefer Von Zoe`
      }
    },
    {
      primary: {
        IT: `SPESSORE DISPONIBILE`,
        EN: `AVAILABLE THICKNESS`
      },
      secondary: {
        IT: `3 mm`,
        EN: `3 mm`
      }
    },
    {
      primary: {
        IT: `FORMATO`,
        EN: `SIZE`
      },
      secondary: {
        IT: `1050x1540 mm`,
        EN: `1050x1540 mm`
      }
    },
    {
      primary: {
        IT: `BOBINE SU RICHIESTA`,
        EN: `REELS UPON REQUEST `
      }
    }
  ],

  table: {
    line: 4,
    EN: "tabella_KR1_EN",
    IT: "tabella_KR1_IT"
  }
};
/********************************************************************************************************/
export const VIDARR = {
  image: "VIDARR",
  title: "VIÐARR",
  description: {
    EN: "Highlights",
    IT: "Descrizione",
    data: [
      {
        IT: `VIÐARR è un fibrato innovativo di alta qualità.
        </br>
        Prodotto con fibre di cellulosa riciclata e proveniente da foreste gestite responsabilmente, VIÐARR conferisce alla soletta rigidità e resistenza torsionale elevate. 
       `,
        EN: `VIÐARR is a high-quality, innovative fibreboard.
        </br>
        Made of recycled cellulose fibre coming from responsibly managed forests, VIÐARR gives insoles advanced stiffness and torsional resistance.`
      }
    ]
  },

  performace: {
    EN: "Performance",
    IT: "Performance",
    data: [
      {
        IT: `• Rigidità elevata: superiore a quella dei comuni fibrati`,
        EN: `• Stiffness that’s superior to common fibreboards`
      },
      {
        IT: `• Tenuta al chiodo ottima`,
        EN: "• Excellent nail retention"
      },
      {
        IT: `• Modellabilità eccellente`,
        EN: `• Excellent modelling`
      },
      {
        IT: `• Prestazioni elevate nel tempo (lunga vita utile) `,
        EN: "• Great performance over time (long life span)"
      },
      {
        IT: `• Leggerezza`,
        EN: `• Lightness `
      }
    ]
  },

  detail: [
    {
      primary: {
        IT: `COLORE`,
        EN: `COLOR`
      },
      secondary: {
        IT: `rosso magenta`,
        EN: `magenta red`
      }
    },
    {
      primary: {
        IT: `MARCATURA`,
        EN: `BRANDING`
      },
      secondary: {
        IT: `VIÐARR - Kiefer Von Zoe`,
        EN: `VIÐARR - Kiefer Von Zoe`
      }
    },
    {
      primary: {
        IT: `SPESSORE DISPONIBILE`,
        EN: `AVAILABLE THICKNESS`
      },
      secondary: {
        IT: `0,8 | 1 | 1,2 mm`,
        EN: `0,8 | 1 | 1,2 mm`
      }
    },
    {
      primary: {
        IT: `FORMATO`,
        EN: `SIZE`
      },
      secondary: {
        IT: `1210x1540 mm`,
        EN: `1210x1540 mm`
      }
    }
  ],

  table: {
    line: 6,
    EN: "tabella_VIDARR_EN",
    IT: "tabella_VIDARR_IT"
  }
};

/********************************************************************************************************/
export const BRL = {
  image: "BRL",
  title: "BRL",
  description: {
    EN: "Highlights",
    IT: "Descrizione",
    data: [
      {
        IT: `
        BRL è una schiuma di lattice bicomponente ottenuta dalla spalmatura
        combinata di due diverse mescole su tessuto stretch. <br/>Il layer inferiore - di colore rosso - contiene lo stesso gel presente in LRC,
        mentre il layer superiore - di colore azzurro - consiste di un lattice speciale
        dalle proprietà viscoelastiche - tipo memory foam.`,
        EN: `BRL is a bi-component latex foam created by combining two different layers, composed of two different mixtures, on stretch fabric. <br/>
        The lower layer (in red) contains the same gel as LRC, while the top layer (light blue) consists of a special type of latex with viscoelastic properties, similar to memory foam.`
      }
    ]
  },

  performace: {
    EN: "Performance",
    IT: "Performance",
    data: [
      {
        IT: `• Effetto fußbett personalizzato`,
        EN: `• The feel of a custom fußbett`
      },
      {
        IT: `• Previene la formazione di grinze da sagomatura`,
        EN: "• Prevents the formation of creases when shaped"
      },
      {
        IT: `• Ritorno elevato`,
        EN: `• Elevated spring-back`
      },
      {
        IT: `• Elevata capacità di assorbimento dell’acqua`,
        EN: "• Elevated moisture absorption "
      },
      {
        IT: `• Elevata capacità di evaporazione dell’acqua`,
        EN: `• Elevated moisture evaporation`
      },
      {
        IT: `• Traspirabilità`,
        EN: `• Breathable `
      },
      {
        IT: `• Comfort`,
        EN: `• Comfortable `
      },
      {
        IT: `• Morbidezza`,
        EN: `• Soft `
      },
      {
        IT: `• Delicatezza sulla pelle`,
        EN: `• Gentle on skin`
      }
    ]
  },

  detail: [
    {
      primary: {
        IT: `COLORE`,
        EN: `COLOR`
      },
      secondary: {
        IT: `azzurro/rosso`,
        EN: `light blue/red`
      }
    },
    {
      primary: {
        IT: `MARCATURA`,
        EN: `BRANDING`
      },
      secondary: {
        IT: `Kiefer Von Zoe`,
        EN: `Kiefer Von Zoe`
      }
    },
    {
      primary: {
        IT: `SPESSORI DISPONIBILI`,
        EN: `AVAILABLE THICKNESSES`
      },
      secondary: {
        IT: `2,5 | 4,5 | 6,5 mm`,
        EN: `2,5 | 4,5 | 6,5 mm`
      }
    },
    {
      primary: {
        IT: `FORMATO (BOBINE) spessore 2,5 mm`,
        EN: `SIZE (REELS) 2,5 mm thickness`
      },
      secondary: {
        IT: `1,5x50 m`,
        EN: `1,5x50 m`
      }
    },
    {
      primary: {
        IT: `FORMATO (BOBINE) spessore 4,5 mm `,
        EN: `SIZE (REELS) 4,5 mm thickness`
      },
      secondary: {
        IT: `1,5x30 m`,
        EN: `1,5x30 m`
      }
    },
    {
      primary: {
        IT: `FORMATO (BOBINE) spessore 6,5 mm`,
        EN: `SIZE (REELS) 6,5 mm thickness`
      },
      secondary: {
        IT: `1,5x20 m`,
        EN: `1,5x20 m`
      }
    }
  ],

  table: {
    line: 2,
    EN: "tabella_brl_EN",
    IT: "tabella_brl_IT"
  },
  extraImage: {
    EN: "disegno_BRL_LRC_HEVEA_PIUMA_NUVOLA_EN",
    IT: "disegno_BRL_LRC_HEVEA_PIUMA_NUVOLA_IT"
  }
};
/********************************************************************************************************/
export const LRC = {
  image: "LRC",
  title: "LRC",
  description: {
    EN: "Highlights",
    IT: "Descrizione",
    data: [
      {
        IT: `
        LRC è una schiuma di lattice naturale e lattice SBR spalmata su tessuto
        stretch.`,
        EN: `LRC is a type of SBR latex foam that's coated on stretch fabric.`
      }
    ]
  },

  performace: {
    EN: "Performance",
    IT: "Performance",
    data: [
      {
        IT: `• Previene la formazione di grinze da sagomatura`,
        EN: `• Prevents the formation of creases when shaped`
      },
      {
        IT: `• Ritorno elevato`,
        EN: `• Elevated spring-back`
      },
      {
        IT: `• Elevata capacità di assorbimento dell’acqua`,
        EN: "• Elevated moisture absorption "
      },
      {
        IT: `• Elevata capacità di evaporazione dell’acqua`,
        EN: `• Elevated moisture evaporation`
      },
      {
        IT: `• Traspirabilità`,
        EN: `• Breathable `
      },
      {
        IT: `• Comfort`,
        EN: `• Comfortable `
      },
      {
        IT: `• Morbidezza`,
        EN: `• Soft `
      },
      {
        IT: `• Delicatezza sulla pelle`,
        EN: `• Gentle on skin`
      }
    ]
  },

  detail: [
    {
      primary: {
        IT: `COLORE`,
        EN: `COLOR`
      },
      secondary: {
        IT: `rosso`,
        EN: `red`
      }
    },
    {
      primary: {
        IT: `MARCATURA`,
        EN: `BRANDING`
      },
      secondary: {
        IT: `Kiefer Von Zoe`,
        EN: `Kiefer Von Zoe`
      }
    },
    {
      primary: {
        IT: `SPESSORE DISPONIBILE`,
        EN: `AVAILABLE THICKNESS`
      },
      secondary: {
        IT: `2 mm`,
        EN: `2 mm`
      }
    },
    {
      primary: {
        IT: `FORMATO (BOBINE)`,
        EN: `SIZE (REELS)`
      },
      secondary: {
        IT: `1,5x50 m`,
        EN: `1,5x50 m`
      }
    }
  ],

  table: {
    line: 1,
    EN: "tabella_LRC_EN",
    IT: "tabella_LRC_IT"
  },
  extraImage: {
    EN: "disegno_BRL_LRC_HEVEA_PIUMA_NUVOLA_EN",
    IT: "disegno_BRL_LRC_HEVEA_PIUMA_NUVOLA_IT"
  }
};
/********************************************************************************************************/
export const LRCS = {
  image: "LRCS",
  title: "LRCS",
  description: {
    EN: "Highlights",
    IT: "Descrizione",
    data: [
      {
        IT: `LRCS è una schiuma di lattice naturale e lattice SBR spalmata su tessuto.`,
        EN: `LRCS is a type of SBR latex foam that's coated on fabric.`
      }
    ]
  },

  performace: {
    EN: "Performance",
    IT: "Performance",
    data: [
      {
        IT: `• Ritorno elevato`,
        EN: `• Elevated spring-back`
      },
      {
        IT: `• Elevata capacità di assorbimento dell’acqua`,
        EN: "• Elevated moisture absorption "
      },
      {
        IT: `• Elevata capacità di evaporazione dell’acqua`,
        EN: `• Elevated moisture evaporation`
      },
      {
        IT: `• Traspirabilità`,
        EN: `• Breathable `
      },
      {
        IT: `• Comfort`,
        EN: `• Comfortable `
      },
      {
        IT: `• Morbidezza`,
        EN: `• Soft `
      },
      {
        IT: `• Delicatezza sulla pelle`,
        EN: `• Gentle on skin`
      }
    ]
  },

  detail: [
    {
      primary: {
        IT: `COLORE`,
        EN: `COLOR`
      },
      secondary: {
        IT: `rosso`,
        EN: `red`
      }
    },
    {
      primary: {
        IT: `MARCATURA`,
        EN: `BRANDING`
      },
      secondary: {
        IT: `Kiefer Von Zoe`,
        EN: `Kiefer Von Zoe`
      }
    },
    {
      primary: {
        IT: `SPESSORE DISPONIBILE`,
        EN: `AVAILABLE THICKNESS`
      },
      secondary: {
        IT: `1,3 mm`,
        EN: `1,3 mm`
      }
    },
    {
      primary: {
        IT: `FORMATO (BOBINE)`,
        EN: `SIZE (REELS)`
      },
      secondary: {
        IT: `1,5x50 m`,
        EN: `1,5x50 m`
      }
    }
  ],

  table: {
    line: 2,
    EN: "tabella_LRCS_EN",
    IT: "tabella_LRCS_EN"
  }
};
/********************************************************************************************************/
export const HEVEA = {
  image: "HEVEA",
  title: "HEVEA",
  description: {
    EN: "Highlights",
    IT: "Descrizione",
    data: [
      {
        IT: `
        HEVEA è una schiuma di lattice naturale con aggiunta di fibre di legno,
caratterizzata da un’alta densità e spalmata su tessuto stretch.
Prima schiuma sul mercato ad essere certificata FSC, HEVEA è realizzata
all’85% con materie prime biodegradabili.`,
        EN: `HEVEA is foam made of natural latex with the addition of wood fibre, characterised by its high density; it’s coated on stretch fabric. 
        The first foam on the market to be  FSC® certified, HEVEA is made of 85% biodegradable raw materials.`
      }
    ]
  },

  performace: {
    EN: "Performance",
    IT: "Performance",
    data: [
      {
        IT: `• Previene la formazione di grinze da sagomatura`,
        EN: "• Prevents the formation of creases when shaped"
      },
      {
        IT: `• Ritorno elevato`,
        EN: `• Elevated spring-back`
      },
      {
        IT: `• Elevata capacità di assorbimento dell’acqua`,
        EN: "• Elevated moisture absorption "
      },
      {
        IT: `• Elevata capacità di evaporazione dell’acqua`,
        EN: `• Elevated moisture evaporation`
      },
      {
        IT: `• Traspirabilità`,
        EN: `• Breathable `
      },
      {
        IT: `• Comfort`,
        EN: `• Comfortable `
      },
      {
        IT: `• Morbidezza`,
        EN: `• Soft `
      },
      {
        IT: `• Delicatezza sulla pelle`,
        EN: `• Gentle on skin`
      }
    ]
  },

  detail: [
    {
      primary: {
        IT: `COLORE`,
        EN: `COLOR`
      },
      secondary: {
        IT: `verde acqua`,
        EN: `aquamarine`
      }
    },
    {
      primary: {
        IT: `MARCATURA`,
        EN: `BRANDING`
      },
      secondary: {
        IT: `HEVEA - Kiefer Von Zoe`,
        EN: `HEVEA - Kiefer Von Zoe`
      }
    },
    {
      primary: {
        IT: `SPESSORI DISPONIBILI`,
        EN: `AVAILABLE THICKNESSES`
      },
      secondary: {
        IT: `1,5 | 2 | 3 | 4 | 6 | 8 mm`,
        EN: `1,5 | 2 | 3 | 4 | 6 | 8 mm`
      }
    },
    {
      primary: {
        IT: `FORMATO (BOBINE)`,
        EN: `SIZE (REELS)`
      },
      secondary: {
        IT: `1,5x50 m`,
        EN: `1,5x50 m`
      }
    }
  ],

  table: {
    line: 3.5,
    EN: "tabella_HEVEA_EN",
    IT: "tabella_HEVEA_IT"
  },
  extraImage: {
    EN: "disegno_BRL_LRC_HEVEA_PIUMA_NUVOLA_EN",
    IT: "disegno_BRL_LRC_HEVEA_PIUMA_NUVOLA_IT"
  }
};
/********************************************************************************************************/
export const PANAMA = {
  image: "PANAMA",
  title: "PANAMA",
  description: {
    EN: "Highlights",
    IT: "Descrizione",
    data: [
      {
        IT: `
        PANAMA è un tessuto di rinforzo 100% cotone (su richiesta, certificato per il contenuto di riciclato) con adesivo biodegradabile.<br/>
        PANAMA è completamente biodegradabile, caratterizzato da lunga vita utile e adatto ad una vasta varietà di substrati.`,
        EN: `PANAMA is a 100% cotton reinforcement fabric (certified for containing recycled material, upon request) with a biodegradable adhesive.
<br/>
PANAMA is 100% biodegradable, characterised by a long life span and well suited to a wide variety of substrates.`
      }
    ]
  },

  performace: {
    EN: "Performance",
    IT: "Performance",
    data: [
      {
        IT: `• Massima modellabilità e leggerezza`,
        EN: "• Maximum modelling"
      },
      {
        IT: `• Massima aderenza`,
        EN: `• Maximum adherence`
      },
      {
        IT: `• Prestazioni elevate nel tempo (lunga vita utile)`,
        EN: "• Great performance over time (long life span)"
      },
      {
        IT: `• Massima traspirabilità (adesivo spalmato a punti)`,
        EN: `• Maximum breathability (dot coated adhesive)`
      }
    ]
  },

  detail: [
    {
      primary: {
        IT: `COLORE`,
        EN: `COLOR`
      },
      secondary: {
        IT: `bianco`,
        EN: `white`
      }
    },
    {
      primary: {
        IT: `MARCATURA`,
        EN: `BRANDING`
      },
      secondary: {
        IT: `-`,
        EN: `-`
      }
    },

    {
      primary: {
        IT: `FORMATO (BOBINE)`,
        EN: `SIZE (REELS)`
      },
      secondary: {
        IT: `1,5x50 m`,
        EN: `1,5x50 m`
      }
    }
  ],

  table: {
    line: 1,
    EN: "tabella_panama_EN",
    IT: "tabella_panama_IT"
  },
  technicalData: [
    {
      title: {
        EN: "Recommended use",
        IT: "Modalità di utilizzo"
      },
      paragraph: [
        {
          EN: "Once positioned on the upper, the reinforcement can be pressed either by using a crimper or a heated hydraulic press.",
          IT: "Il rinforzo, posizionato sulla tomaia, può essere pressato sia utilizzando una cambratrice che una pressa idraulica riscaldata."
        }
      ]
    },
    {
      paragraph: [
        {
          EN: "We recommend the following standard conditions when using this product:",
          IT: "Consigliamo le seguenti condizioni standard di lavoro:"
        },
        {
          EN: `• plate temperature: 90°C - 150°C depending on the type and thickness of the leather<br/>
          • closure time: 10-15 seconds (higher temperatures equate to shorter closure times and vice versa)<br/>
          • pressure: 3-5 atm`,
          IT: `• temperatura della piastra: 90°C - 150°C a seconda del tipo e dello spessore della pelle <br/>
      • tempo di chiusura: 10-15 secondi (temperature più elevate permettono tempi di chiusura minori e viceversa)<br/>
      • pressione: 3-5 atm`
        }
      ]
    },
    {
      paragraph: [
        {
          EN: "Using a crimper, the operation can be repeated multiple times so that the upper is perfectly moulded as desired.",
          IT: "Utilizzando una cambratrice, l’operazione può essere ripetuta più volte in modo da meglio sagomare la tomaia."
        }
      ]
    },
    {
      title: {
        EN: "Warning:",
        IT: "Attenzione:"
      },
      paragraph: [
        {
          EN: "do not expose to direct sunlight or very hot and humid environments.",
          IT: "non esporre alla luce solare diretta nè ad ambienti molto caldi e umidi"
        }
      ]
    }
  ]
};
/********************************************************************************************************/
export const BELIZE = {
  image: "BELIZE",
  title: "BELIZE",
  description: {
    EN: "Highlights",
    IT: "Descrizione",
    data: [
      {
        IT: `
        BELIZE è un rinforzo jersey 100% cotone (su richiesta, certificato per il contenuto di riciclato) con adesivo biodegradabile.<br/>BELIZE è completamente biodegradabile, caratterizzato da lunga vita utile e adatto ad una vasta varietà di substrati.`,
        EN: `BELIZE is a 100% cotton jersey reinforcement (certified for containing recycled material, upon request) with a biodegradable adhesive.<br/>BELIZE is 100% biodegradable, characterised by a long life span and well suited to a wide variety of substrates.`
      }
    ]
  },

  performace: {
    EN: "Performance",
    IT: "Performance",
    data: [
      {
        IT: `• Massima modellabilità e leggerezza`,
        EN: "• Maximum modelling"
      },
      {
        IT: `• Massima aderenza`,
        EN: `• Maximum adherence`
      },
      {
        IT: `• Ottima cambratura`,
        EN: `• Optimal crimping`
      },
      {
        IT: `• Prestazioni elevate nel tempo (lunga vita utile)`,
        EN: "• Great performance over time (long life span)"
      },
      {
        IT: `• Massima traspirabilità (adesivo spalmato a punti)`,
        EN: `• Maximum breathability (dot coated adhesive)`
      }
    ]
  },

  detail: [
    {
      primary: {
        IT: `COLORE`,
        EN: `COLOR`
      },
      secondary: {
        IT: `bianco`,
        EN: `white`
      }
    },
    {
      primary: {
        IT: `MARCATURA`,
        EN: `BRANDING`
      },
      secondary: {
        IT: `-`,
        EN: `-`
      }
    },

    {
      primary: {
        IT: `FORMATO (BOBINE)`,
        EN: `SIZE (REELS)`
      },
      secondary: {
        IT: `1,5x50 m`,
        EN: `1,5x50 m`
      }
    }
  ],

  extraImage: {
    EN: "disegno_belize_nicaragua_EN",
    IT: "disegno_belize_nicaragua_IT"
  },
  table: {
    line: 1,
    EN: "tabella_belize_EN",
    IT: "tabella_belize_IT"
  },
  technicalData: [
    {
      title: {
        EN: "Recommended use",
        IT: "Modalità di utilizzo"
      },
      paragraph: [
        {
          EN: "Crimping the leather is carried out mechanically, forcing the front of the upper between two metal plates that have been heated to a temperature generally set between 70-90°C.",
          IT: "La cambratura delle pelle è effettuata meccanicamente forzando la parte anteriore della tomaia tra due piastre metalliche riscaldate ad una temperatura solitamente impostata nell’intervallo 70-90°C."
        },
        {
          EN: "Once positioned on the upper, the reinforcement can be pressed either by using a crimper or a heated hydraulic press.",
          IT: "Il rinforzo, posizionato sulla tomaia, può essere pressato sia utilizzando una cambratrice che una pressa idraulica riscaldata."
        }
      ]
    },

    {
      paragraph: [
        {
          EN: "We recommend the following standard conditions when using this product:",
          IT: "Consigliamo le seguenti condizioni standard di lavoro:"
        },
        {
          EN: `• plate temperature: 90°C - 150°C depending on the type and thickness of the leather<br/>
          • closure time: 5-12 seconds (higher temperatures equate to shorter closure times and vice versa)<br/>
          • pressure: 3-5 atm`,
          IT: `• temperatura della piastra: 90°C - 150°C a seconda del tipo e dello spessore della pelle <br/>
      • tempo di chiusura: 5-12 secondi (temperature più elevate permettono tempi di chiusura
        minori e viceversa)<br/>
      • pressione: 3-5 atm`
        }
      ]
    },
    {
      paragraph: [
        {
          EN: "Using a crimper, the operation can be repeated multiple times so that the upper is perfectly moulded as desired.",
          IT: "Utilizzando una cambratrice, l’operazione può essere ripetuta più volte in modo da meglio sagomare la tomaia."
        }
      ]
    },
    {
      title: {
        EN: "Warning:",
        IT: "Attenzione:"
      },
      paragraph: [
        {
          EN: "do not expose to direct sunlight or very hot and humid environments.",
          IT: "non esporre alla luce solare diretta nè ad ambienti molto caldi e umidi"
        }
      ]
    }
  ]
};
/********************************************************************************************************/
export const NICARAGUA = {
  image: "NICARAGUA",
  title: "NICARAGUA",
  description: {
    EN: "Highlights",
    IT: "Descrizione",
    data: [
      {
        IT: `
        NICARAGUA è un rinforzo jersey 100% cotone (su richiesta, certificato per il contenuto di riciclato) con adesivo biodegradabile.<br/>NICARAGUA è completamente biodegradabile, caratterizzato da lunga vita utile e adatto ad una vasta varietà di substrati.`,
        EN: `NICARAGUA is a 100% cotton jersey reinforcement (certified for containing recycled material, upon request) with a biodegradable adhesive.<br/>BELIZE is 100% biodegradable, characterised by a long life span and well suited to a wide variety of substrates.`
      }
    ]
  },

  performace: {
    EN: "Performance",
    IT: "Performance",
    data: [
      {
        IT: `• Massima modellabilità e leggerezza`,
        EN: "• Maximum modelling"
      },
      {
        IT: `• Massima aderenza`,
        EN: `• Maximum adherence`
      },
      {
        IT: `• Ottima cambratura`,
        EN: `• Optimal crimping`
      },
      {
        IT: `• Prestazioni elevate nel tempo (lunga vita utile)`,
        EN: "• Great performance over time (long life span)"
      },
      {
        IT: `• Massima traspirabilità (adesivo spalmato a punti)`,
        EN: `• Maximum breathability (dot coated adhesive)`
      },
      {
        IT: `• Possibilità di utilizzo nella realizzazione dei tobolari`,
        EN: `• Possible uses in tubular fabrication`
      }
    ]
  },

  detail: [
    {
      primary: {
        IT: `COLORE`,
        EN: `COLOR`
      },
      secondary: {
        IT: `bianco`,
        EN: `white`
      }
    },
    {
      primary: {
        IT: `MARCATURA`,
        EN: `BRANDING`
      },
      secondary: {
        IT: `-`,
        EN: `-`
      }
    },

    {
      primary: {
        IT: `FORMATO (BOBINE)`,
        EN: `SIZE (REELS)`
      },
      secondary: {
        IT: `1,5x50 m`,
        EN: `1,5x50 m`
      }
    }
  ],

  table: {
    line: 1,
    EN: "tabella_nicaragua_EN",
    IT: "tabella_nicaragua_IT"
  },
  extraImage: {
    EN: "disegno_belize_nicaragua_EN",
    IT: "disegno_belize_nicaragua_IT"
  },
  technicalData: [
    {
      title: {
        EN: "Recommended use",
        IT: "Modalità di utilizzo"
      },
      paragraph: [
        {
          EN: "Crimping the leather is carried out mechanically, forcing the front of the upper between two metal plates that have been heated to a temperature generally set between 70-90°C.",
          IT: "La cambratura delle pelle è effettuata meccanicamente forzando la parte anteriore della tomaia tra due piastre metalliche riscaldate ad una temperatura solitamente impostata nell’intervallo 70-90°C."
        },
        {
          EN: "Once positioned on the upper, the reinforcement can be pressed either by using a crimper or a heated hydraulic press.",
          IT: "Il rinforzo, posizionato sulla tomaia, può essere pressato sia utilizzando una cambratrice che una pressa idraulica riscaldata."
        }
      ]
    },

    {
      paragraph: [
        {
          EN: "We recommend the following standard conditions when using this product:",
          IT: "Consigliamo le seguenti condizioni standard di lavoro:"
        },
        {
          EN: `• plate temperature: 90°C - 150°C depending on the type and thickness of the leather<br/>
          • closure time: 5-12 seconds (higher temperatures equate to shorter closure times and vice versa)<br/>
          • pressure: 3-5 atm`,
          IT: `• temperatura della piastra: 90°C - 150°C a seconda del tipo e dello spessore della pelle <br/>
      • tempo di chiusura: 5-12 secondi (temperature più elevate permettono tempi di chiusura
        minori e viceversa)<br/>
      • pressione: 3-5 atm`
        }
      ]
    },
    {
      paragraph: [
        {
          EN: "Using a crimper, the operation can be repeated multiple times so that the upper is perfectly moulded as desired.",
          IT: "Utilizzando una cambratrice, l’operazione può essere ripetuta più volte in modo da meglio sagomare la tomaia."
        }
      ]
    },
    {
      title: {
        EN: "Warning:",
        IT: "Attenzione:"
      },
      paragraph: [
        {
          EN: "do not expose to direct sunlight or very hot and humid environments.",
          IT: "non esporre alla luce solare diretta nè ad ambienti molto caldi e umidi"
        }
      ]
    }
  ]
};
/********************************************************************************************************/
export const BIO = {
  image: "BIO",
  title: "BIO",
  description: {
    EN: "Highlights",
    IT: "Descrizione",
    data: [
      {
        IT: `
        BIO è un materiale termoadesivo innovativo progettato per la produzione di puntali, contrafforti e rinforzi per calzatura e pelletteria.<br/>
        Realizzato a base cotone (su richiesta, certificato per il contenuto di riciclato) e impregnato di resina biodegradabile, BIO è 100% biodegradabile, caratterizzato da una lunga vita utile e adatto ad una vasta varietà di substrati.`,
        EN: `BIO is a conceptually innovative heat-activated adhesive material designed for the production of toe boxes, heel counters and reinforcements for leather goods. 
<br/>
Made of cotton (certified for containing recycled material, upon request), BIO is 100% biodegradable, characterised by a long life span and well suited to a wide variety of substrates.`
      }
    ]
  },

  performace: {
    EN: "Performance",
    IT: "Performance",
    data: [
      {
        IT: `• Massima modellabilità e leggerezza`,
        EN: "• Maximum modelling"
      },
      {
        IT: `• Massima aderenza`,
        EN: `• Maximum adherence`
      },
      {
        IT: `• Scarnitura estremamente sottile`,
        EN: `• Extremely thin skiving`
      },
      {
        IT: `• Collante a pieno cuore`,
        EN: `• Fully permeated with adhesive`
      },
      {
        IT: `• Prestazioni elevate nel tempo (lunga vita utile)`,
        EN: "• Great performance over time (long life span)"
      }
    ]
  },

  detail: [
    {
      primary: {
        IT: `COLORE`,
        EN: `COLOR`
      },
      secondary: {
        IT: `bianco`,
        EN: `white`
      }
    },
    {
      primary: {
        IT: `MARCATURA`,
        EN: `BRANDING`
      },
      secondary: {
        IT: `-`,
        EN: `-`
      }
    },
    {
      primary: {
        IT: `SPESSORI DISPONIBILI`,
        EN: `AVAILABLE THICKNESSES`
      },
      secondary: {
        IT: `0.6 | 1 | 1.35 | 1.5 mm`,
        EN: `0.6 | 1 | 1.35 | 1.5 mm`
      }
    },
    {
      primary: {
        IT: `FORMATO `,
        EN: `SIZE `
      },
      secondary: {
        IT: `1000x1450 mm`,
        EN: `1000x1450 mm`
      }
    }
  ],

  extraImage: {
    EN: "disegno_bio_EN",
    IT: "disegno_bio_IT"
  },
  technicalData: [
    {
      title: {
        EN: "Recommended use",
        IT: "Modalità di utilizzo"
      },
      paragraph: [
        {
          EN: "We recommend the following standard conditions when using this product:",
          IT: "Consigliamo le seguenti condizioni standard di lavoro:"
        },
        {
          EN: `• temperature of the mould: 130°C - 150°C depending on the lining and the upper used<br/>
          • minimum temperature of the facing: 100°C<br/>
          • contact time: 10-15 seconds (higher temperatures require shorter contact times and vice-versa)`,
          IT: `• temperatura della forma: 130°C - 150°C a seconda della fodera e della tomaia utilizzate <br/>
      • temperatura minima di intefaccia: 100°C<br/>
      • tempo di contatto: 10-15 secondi (temperature più elevate permettono tempi di contatto
        minori e viceversa)`
        }
      ]
    },

    {
      paragraph: [
        {
          EN: "We recommend carrying out a preliminary gluing test as application conditions may vary according to the upper used. ",
          IT: "Consigliamo di effettuare una prova preliminare di incollaggio in quanto le condizioni di applicazione possono variare al variare della tomaia."
        },
        {
          EN: "The subsequent cold setting step (which can be carried out using two-position heel-shaping machines) makes it possible to create a perfectly moulded heel counter",
          IT: "La successiva stabilizzazione a freddo (realizzabile nelle garbasperoni a due posizioni) permette di ottenere un contrafforte perfettamente modellato.<br/>Le stesse indicazioni valgono anche per la produzione di puntali e rinforzi."
        }
      ]
    }
  ]
};
/********************************************************************************************************/
export const PK = {
  image: "PK",
  title: "PK",
  description: {
    EN: "Highlights",
    IT: "Descrizione",
    data: [
      {
        IT: `
        PK è un film termoadesivo a base di EVA su supporto di carta.<br/>
Questa pellicola trova applicazione prevalentemente nel settore
calzaturiero e nella pelletteria.`,
        EN: `PK is an EVA-based heat-activated film on a paper support. <br/>

        This film is mainly used in the footwear and leather goods industries.`
      }
    ]
  },

  performace: {
    EN: "Performance",
    IT: "Performance",
    data: [
      {
        IT: `• Elimina le grinze che si formano tra tomaia e fodera`,
        EN: "• Eliminates the wrinkles that form between the upper and lining"
      },
      {
        IT: `• Conferisce alla calzatura un aspetto perfettamente stirato`,
        EN: `• Gives footwear a perfectly taut appearance`
      },
      {
        IT: `• Rende invisibili tutti i componenti interni che possono marcare la
        tomaia`,
        EN: `• Makes allthe inner components that can mark the upper invisible`
      },
      {
        IT: `• Permette di eliminare alcuni rinforzi, così da ottenere una calzatura
        più morbida`,
        EN: "• Makes it possible to eliminate some reinforcements, thereby creating softer footwear"
      },
      {
        IT: `• Riproduce fedelmente le linee della forma, anche dove non sono
        presenti componenti sagomati`,
        EN: `• Accurately reproduces  the lines of the last, even in the absence of contoured components`
      },
      {
        IT: `• Grazie alla presenza del supporto in carta, può essere utilizzata in due
        tempi, incollando un lato per volta`,
        EN: `• Thanks to the paper support, it can be used in a two-step process, gluing one side at a time`
      }
    ]
  },

  detail: [
    {
      primary: {
        IT: `COLORE`,
        EN: `COLOR`
      },
      secondary: {
        IT: `bianco`,
        EN: `white`
      }
    },
    {
      primary: {
        IT: `MARCATURA`,
        EN: `BRANDING`
      },
      secondary: {
        IT: `-`,
        EN: `-`
      }
    },

    {
      primary: {
        IT: `FORMATO (BOBINE)`,
        EN: `SIZE (REELS)`
      },
      secondary: {
        IT: `1,5x50 m`,
        EN: `1,5x50 m`
      }
    }
  ],

  table: {
    line: 1,
    EN: "tabella_PK_EN",
    IT: "tabella_PK_IT"
  },
  technicalData: [
    {
      title: {
        EN: "Recommended use",
        IT: "Modalità di utilizzo"
      },
      paragraph: [
        {
          EN: "PK is re-activated by heat, at a variable temperature between 90° and 110°C (temperature of the adhesive film), depending on the material to be glued and the working method used.",
          IT: "PK è riattivabile a caldo ad una temperatura variabile tra i 90°C e i 110°C (temperatura del film adesivo), a seconda del materiale da incollare e del metodo di lavoro utilizzato."
        },
        {
          EN: "To create an optimised bond, just apply PK to the area to be glued and reactivate it with a plate heated to the indicated temperature, applying pressure.",
          IT: "Per ottenere un ottimo fissaggio è sufficiente stendere PK sulle aree da incollare e riattivarla con una piastra calda alle temperature indicate sotto pressione."
        }
      ]
    }
  ]
};
/********************************************************************************************************/
export const PIUMA = {
  image: "PIUMA",
  title: "PIUMA",
  description: {
    EN: "Highlights",
    IT: "Descrizione",
    data: [
      {
        IT: `
        PIUMA è un materiale composito per solette che unisce le caratteristiche
        di HEVEA (cfr. pagina dedicata) a quelle di un TNT coagulato
        termofissato.<br/>
        HEVEA, la prima schiuma sul mercato ad essere certificata FSC, è
        composta all’85% da materie prime biodegradabili.<br/>
        Il TNT, composto da fibre botaniche e PLA di origine vegetale, è realizzato
        al 100% con materie prime biodegradabili ed è privo di leganti chimici.`,
        EN: `PIUMA is a composite material for insoles that combines the characteristics of HEVEA (see the dedicated info sheet) and those of a  heat-set coagulated non-woven fabric.<br/>
        The first foam on the market to be  FSC® certified, HEVEA is composed of 85% biodegradable raw materials. <br/>
        Composed of botanical fibres andplant-derived PLA, the non-woven fabric is 100% made from biodegradable raw materials and entirely free of chemical binding agents.`
      }
    ]
  },

  performace: {
    EN: "Performance",
    IT: "Performance",
    data: [
      {
        IT: `• Adatto alla cucitura strobel`,
        EN: "• Can be used with Strobel construction"
      },
      {
        IT: `• Ritorno elevato`,
        EN: `• Elevated spring-back`
      },
      {
        IT: `• Elevata capacità di assorbimento dell’acqua`,
        EN: `• Elevated moisture absorption`
      },
      {
        IT: `• Elevata capacità di evaporazione dell’acqua`,
        EN: "• Elevated moisture evaporation "
      },
      {
        IT: `• Traspirabilità`,
        EN: `• Breathable`
      },
      {
        IT: `• Comfort`,
        EN: `• Comfortable`
      },
      {
        IT: `• Morbidezza`,
        EN: `• Soft`
      },
      {
        IT: `• Delicatezza sulla pelle`,
        EN: `• Gentle on skin`
      }
    ]
  },

  detail: [
    {
      primary: {
        IT: `COLORE`,
        EN: `COLOR`
      },
      secondary: {
        IT: `verde acqua/bianco`,
        EN: `aquamarine/white`
      }
    },
    {
      primary: {
        IT: `MARCATURA`,
        EN: `BRANDING`
      },
      secondary: {
        IT: `-`,
        EN: `-`
      }
    },
    {
      primary: {
        IT: `SPESSORI DISPONIBILI`,
        EN: `AVAILABLE THICKNESS`
      },
      secondary: {
        IT: `3,7 mm`,
        EN: `3,7 mm`
      }
    },
    {
      primary: {
        IT: `FORMATO (BOBINE)`,
        EN: `SIZE (REELS)`
      },
      secondary: {
        IT: `1,5x50 m`,
        EN: `1,5x50 m`
      }
    }
  ],

  table: {
    line: 3.5,
    EN: "tabella_HEVEA_EN",
    IT: "tabella_HEVEA_IT"
  },
  extraImage: {
    EN: "disegno_BRL_LRC_HEVEA_PIUMA_NUVOLA_EN",
    IT: "disegno_BRL_LRC_HEVEA_PIUMA_NUVOLA_IT"
  }
};
/********************************************************************************************************/
export const NUVOLA = {
  image: "NUVOLA",
  title: "NUVOLA",
  description: {
    EN: "Highlights",
    IT: "Descrizione",
    data: [
      {
        IT: `
        NUVOLA è un materiale composito per solette che unisce le
caratteristiche di HEVEA (cfr. pagina dedicata) a quelle di un TNT
coagulato termofissato.<br/>
HEVEA, la prima schiuma sul mercato ad essere certificata FSC, è
composta all’85% da materie prime biodegradabili.<br/>
Il TNT, composto da fibre botaniche e PLA di origine vegetale, è realizzato
al 100% con materie prime biodegradabili ed è privo di leganti chimici.`,
        EN: `NUVOLA is a composite material for insoles that combines the characteristics of HEVEA (see the dedicated info sheet) and those of a heat-set coagulated non-woven fabric. <br/>
        The first foam on the market to be  FSC® certified, HEVEA is composed of 85% biodegradable raw materials. <br/>
        Composed of botanical fibres andplant-derived PLA, the non-woven fabric is 100% made from biodegradable raw materials and entirely free of chemical binding agents.`
      }
    ]
  },

  performace: {
    EN: "Performance",
    IT: "Performance",
    data: [
      {
        IT: `• Adatto alla cucitura strobel`,
        EN: "• Can be used with Strobel construction"
      },
      {
        IT: `• Ritorno elevato`,
        EN: `• Elevated spring-back`
      },
      {
        IT: `• Elevata capacità di assorbimento dell’acqua`,
        EN: `• Elevated moisture absorption`
      },
      {
        IT: `• Elevata capacità di evaporazione dell’acqua`,
        EN: "• Elevated moisture evaporation "
      },
      {
        IT: `• Traspirabilità`,
        EN: `• Breathable`
      },
      {
        IT: `• Comfort`,
        EN: `• Comfortable`
      },
      {
        IT: `• Morbidezza`,
        EN: `• Soft`
      },
      {
        IT: `• Delicatezza sulla pelle`,
        EN: `• Gentle on skin`
      }
    ]
  },

  detail: [
    {
      primary: {
        IT: `COLORE`,
        EN: `COLOR`
      },
      secondary: {
        IT: `verde acqua/bianco`,
        EN: `aquamarine/white`
      }
    },
    {
      primary: {
        IT: `MARCATURA`,
        EN: `BRANDING`
      },
      secondary: {
        IT: `HEVEA - Kiefer Von Zoe`,
        EN: `HEVEA - Kiefer Von Zoe`
      }
    },
    {
      primary: {
        IT: `SPESSORI DISPONIBILI`,
        EN: `AVAILABLE THICKNESS`
      },
      secondary: {
        IT: `3,7 mm`,
        EN: `3,7 mm`
      }
    },
    {
      primary: {
        IT: `FORMATO (BOBINE)`,
        EN: `SIZE (REELS)`
      },
      secondary: {
        IT: `1,5x50 m`,
        EN: `1,5x50 m`
      }
    }
  ],

  table: {
    line: 3.5,
    EN: "tabella_HEVEA_EN",
    IT: "tabella_HEVEA_IT"
  },
  extraImage: {
    EN: "disegno_BRL_LRC_HEVEA_PIUMA_NUVOLA_EN",
    IT: "disegno_BRL_LRC_HEVEA_PIUMA_NUVOLA_IT"
  }
};
/********************************************************************************************************/
export const EUCALYPTUS = {
  image: "EUCALYPTUS",
  title: "EUCALYPTUS",
  description: {
    EN: "Highlights",
    IT: "Descrizione",
    data: [
      {
        IT: `
        "EUCALYPTUS è un tessuto non tessuto coagulato termofissato adatto alla
cucitura Strobel e alle imbottiture. Composto da fibre botaniche e PLA di origine vegetale,è realizzato al 100% con materie prime biodegradabili, privo di leganti
chimici e certicato FSC.`,
        EN: `EUCALYPTUS is a thermobonded coagulated non-woven fabric suitable for Strobel stitching and padding. Composed of botanical fibres and PLA of vegetable origin, is made from 100% biodegradable raw materials, free of chemical binders chemical binders and FSC-certified.`
      }
    ]
  },

  performace: {
    EN: "Performance",
    IT: "Performance",
    data: [
      {
        IT: `• Adatto alla cucitura strobel`,
        EN: "• Can be used with Strobel construction"
      },
      {
        IT: `• Elevata capacità di assorbimento dell’acqua`,
        EN: `• Elevated moisture absorption`
      },
      {
        IT: `• Elevata capacità di evaporazione dell’acqua`,
        EN: "• Elevated moisture evaporation "
      },
      {
        IT: `• Traspirabilità`,
        EN: `• Breathable`
      },
      {
        IT: `• Comfort`,
        EN: `• Comfortable`
      },
      {
        IT: `• Morbidezza`,
        EN: `• Soft`
      },
      {
        IT: `• Delicatezza sulla pelle`,
        EN: `• Gentle on skin`
      }
    ]
  },

  detail: [
    {
      primary: {
        IT: `COLORE`,
        EN: `COLOR`
      },
      secondary: {
        IT: `bianco`,
        EN: `white`
      }
    },
    {
      primary: {
        IT: `MARCATURA`,
        EN: `BRANDING`
      },
      secondary: {
        IT: `-`,
        EN: `-`
      }
    },
    {
      primary: {
        IT: `SPESSORI DISPONIBILI`,
        EN: `AVAILABLE THICKNESSES `
      },
      secondary: {
        IT: `1,5 | 2 mm`,
        EN: `1,5 | 2 mm`
      }
    },
    {
      primary: {
        IT: `FORMATO (BOBINE)`,
        EN: `SIZE (REELS)`
      },
      secondary: {
        IT: `1,6x40 m`,
        EN: `1,6x40 m`
      }
    }
  ],

  table: {
    line: 4,
    EN: "tabella_eucalyptus_EN",
    IT: "tabella_eucalyptus_IT"
  }
};
/********************************************************************************************************/
export const HOLLAND = {
  image: "HOLLAND",
  title: "HOLLAND",
  description: {
    EN: "Highlights",
    IT: "Descrizione",
    data: [
      {
        IT: `
        HOLLAND è un tessuto non tessuto di fibre sintetiche sviluppato appositamente per la cucitura Strobel.
<br/>
HOLLAND è studiato sia per calzature realizzate per iniezione diretta o per colata (PU, PVC e TR) che per applicazioni a suola incollata o scarpa montata. Particolarmente indicato per iniezione con PU, si adatta perfettamente anche alle cuciture in piano.`,
        EN: `HOLLAND is a nonwoven synthetic fiber fabric developed specifically for Strobel stitching.
        <br/>
        HOLLAND is designed both for footwear made by direct injection or casting (PU, PVC and TR) and for glued sole or fitted shoe applications. Particularly suitable for PU injection, HOLLAND is also perfectly suited for flat stitching.`
      }
    ]
  },

  performace: {
    EN: "Performance",
    IT: "Performance",
    data: [
      {
        IT: `• Adatto alla cucitura strobel`,
        EN: "• Designed for strobel stitching"
      },
      {
        IT: `• Stabilità dimensionale`,
        EN: `• Dimensional stability`
      },
      {
        IT: `• Resistenza alla cucitura elevata`,
        EN: `• High stitching resistance`
      },
      {
        IT: `• Traspirabilità`,
        EN: `• Breathable` //TODO: coerenza
      },
      {
        IT: `• Comfort`,
        EN: `• Comfortable`
      },
      {
        IT: `• Morbidezza`,
        EN: `• Soft` //TODO: coerenza
      },
      {
        IT: `• Delicatezza sulla pelle`,
        EN: `• Gentle on skin` //TODO: coerenza
      }
    ]
  },

  detail: [
    {
      primary: {
        IT: `COLORE`,
        EN: `COLOR`
      },
      secondary: {
        IT: `bianco`,
        EN: `white`
      }
    },
    {
      primary: {
        IT: `MARCATURA`,
        EN: `BRANDING`
      },
      secondary: {
        IT: `-`,
        EN: `-`
      }
    },
    {
      primary: {
        IT: `SPESSORI DISPONIBILI`,
        EN: `AVAILABLE THICKNESS`
      },
      secondary: {
        IT: `1,5 | 2 mm`,
        EN: `1,5 | 2 mm`
      }
    },
    {
      primary: {
        IT: `FORMATO (BOBINE)`,
        EN: `SIZE (REELS)`
      },
      secondary: {
        IT: `1,6x40 m`,
        EN: `1,6x40 m`
      }
    }
  ],

  table: {
    line: 7.5,
    EN: "tabella_HOLLAND_EN",
    IT: "tabella_HOLLAND_IT"
  }
  // extraImage: {
  //   EN: "disegno_BRL_LRC_HEVEA_PIUMA_NUVOLA_EN",
  //   IT: "disegno_BRL_LRC_HEVEA_PIUMA_NUVOLA_IT"
  // }
};
/********************************************************************************************************/
export const EUCALYPTUS_SOFT = {
  image: "EUCALYPTUS_SOFT",
  title: "EUCALYPTUS SOFT",
  description: {
    EN: "Highlights",
    IT: "Descrizione",
    data: [
      {
        IT: `
        EUCALYPTUS SOFT è un tessuto non tessuto coagulato termofissato studiato
        per la cucitura Strobel. 
        <br/>
        Composto da fibre botaniche e PLA di origine vegetale,
        EUCALYPTUS SOFT è realizzato al 100% con materie prime biodegradabili,
        privo di leganti chimici e certicato FSC.`,
        EN: `EUCALYPTUS SOFT is a thermobonded coagulated non-woven fabric suitable for Strobel stitching and padding. 
<br/>
        Composed of botanical fibres and PLA of vegetable origin, is made from 100% biodegradable raw materials, free of chemical binders chemical binders and FSC-certified.`
      }
    ]
  },
  performace: {
    EN: "Performance",
    IT: "Performance",
    data: [
      {
        IT: `• Adatto alla cucitura strobel`,
        EN: "• Designed for strobel stitching"
      },
      {
        IT: `• Elevata capacità di assorbimento dell’acqua`,
        EN: `• Elevated moisture absorption`
      },
      {
        IT: `• Elevata capacità di evaporazione dell’acqua`,
        EN: "• Elevated moisture evaporation "
      },
      {
        IT: `• Traspirabilità`,
        EN: `• Breathable` //TODO: coerenza
      },
      {
        IT: `• Comfort`,
        EN: `• Comfortable`
      },
      {
        IT: `• Morbidezza`,
        EN: `• Soft` //TODO: coerenza
      },
      {
        IT: `• Delicatezza sulla pelle`,
        EN: `• Gentle on skin` //TODO: coerenza
      }
    ]
  },

  detail: [
    {
      primary: {
        IT: `COLORE`,
        EN: `COLOR`
      },
      secondary: {
        IT: `bianco`,
        EN: `white`
      }
    },
    {
      primary: {
        IT: `MARCATURA`,
        EN: `BRANDING`
      },
      secondary: {
        IT: `-`,
        EN: `-`
      }
    },
    {
      primary: {
        IT: `SPESSORI DISPONIBILI`,
        EN: `AVAILABLE THICKNESS`
      },
      secondary: {
        IT: `1,5 | 2 mm`,
        EN: `1,5 | 2 mm`
      }
    },
    {
      primary: {
        IT: `FORMATO (BOBINE)`,
        EN: `SIZE (REELS)`
      },
      secondary: {
        IT: `1,6x40 m`,
        EN: `1,6x40 m`
      }
    }
  ],

  table: {
    line: 7.5,
    EN: "tabella_EUCALYPTUS_SOFT_EN",
    IT: "tabella_EUCALYPTUS_SOFT_IT"
  }
};
/********************************************************************************************************/
export const EUCALYPTUS_STRONG = {
  image: "EUCALYPTUS_STRONG",
  title: "EUCALYPTUS STRONG",
  description: {
    EN: "Highlights",
    IT: "Descrizione",
    data: [
      {
        IT: `
        EUCALYPTUS STRONG è un tessuto non tessuto coagulato termofissato
        studiato per la cucitura Strobel. 
        <br/>
        Composto da fibre botaniche e PLA di origine
        vegetale, EUCALYPTUS STRONG è realizzato al 100% con materie prime
        biodegradabili, privo di leganti chimici e certicato FSC.`,
        EN: `EUCALYPTUS STRONG is a thermobonded coagulated non-woven fabric suitable for Strobel stitching and padding. 
<br/>
        Composed of botanical fibres and PLA of vegetable origin, is made from 100% biodegradable raw materials, free of chemical binders chemical binders and FSC-certified.`
      }
    ]
  },
  performace: {
    EN: "Performance",
    IT: "Performance",
    data: [
      {
        IT: `• Adatto alla cucitura strobel`,
        EN: "• Designed for strobel stitching"
      },
      {
        IT: `• Elevata capacità di assorbimento dell’acqua`,
        EN: `• Elevated moisture absorption`
      },
      {
        IT: `• Elevata capacità di evaporazione dell’acqua`,
        EN: "• Elevated moisture evaporation "
      },
      {
        IT: `• Traspirabilità`,
        EN: `• Breathable` //TODO: coerenza
      },
      {
        IT: `• Comfort`,
        EN: `• Comfortable`
      },
      {
        IT: `• Morbidezza`,
        EN: `• Soft` //TODO: coerenza
      },
      {
        IT: `• Delicatezza sulla pelle`,
        EN: `• Gentle on skin` //TODO: coerenza
      }
    ]
  },

  detail: [
    {
      primary: {
        IT: `COLORE`,
        EN: `COLOR`
      },
      secondary: {
        IT: `bianco`,
        EN: `white`
      }
    },
    {
      primary: {
        IT: `MARCATURA`,
        EN: `BRANDING`
      },
      secondary: {
        IT: `-`,
        EN: `-`
      }
    },
    {
      primary: {
        IT: `SPESSORI DISPONIBILI`,
        EN: `AVAILABLE THICKNESS`
      },
      secondary: {
        IT: `1,5 | 2 mm`,
        EN: `1,5 | 2 mm`
      }
    },
    {
      primary: {
        IT: `FORMATO (BOBINE)`,
        EN: `SIZE (REELS)`
      },
      secondary: {
        IT: `1,6x40 m`,
        EN: `1,6x40 m`
      }
    }
  ],

  table: {
    line: 7.5,
    EN: "tabella_EUCALYPTUS_STRONG_EN",
    IT: "tabella_EUCALYPTUS_STRONG_IT"
  }
};
