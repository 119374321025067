<template>
  <div class="kvz-materials-container container-fluid">
    <div class="text-context">{{ getTitile() }}</div>
    <div class="option-context w-100 d-flex flex-wrap">
      <kvz-back-to
        :label="backTo[selectedLanguage]"
        name="materialsContext"
        :params="{
          context: $route.params.context,
          parent: $route.params.parent
        }"
        :center="true"
      />
      <router-link
        v-for="materialType in contextMaterial"
        :key="materialType.path"
        class="m-2 m-md-3"
        :to="{
          name: 'materialsContextParent',
          params: {
            context: $route.params.context,
            parent: $route.params.parent,
            child: materialType.path
          }
        }"
      >
        {{ materialType[selectedLanguage] }}
      </router-link>
    </div>
    <div class="row content-wrapper">
      <div class="col-12 col-md-4">
        <h3 class="material-text">{{ currentData.title }}</h3>
        <img
          class="material-image"
          :src="helper.getImage(currentData.image)"
          loading="lazy"
          :alt="currentData.title"
        />
        <div class="data-container">
          <div v-for="(detail, key) in currentData.detail" :key="key">
            <div
              class="data-title d-flex align-items-center justify-content-start"
            >
              <div class="line short" />
              <h6>{{ detail.primary[selectedLanguage] }}</h6>
            </div>
            <div v-if="detail.secondary" class="data-content">
              {{ detail.secondary[selectedLanguage] }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8 custom-col">
        <div class="description-container extra-padding">
          <div
            v-for="(description, key) in currentData.description.data"
            :key="key"
            class="description"
            v-html="description[selectedLanguage]"
          />
        </div>
        <div class="performance-container extra-padding">
          <kvz-accordion-material :data="currentData.performace" />
        </div>

        <div
          v-if="currentData.table"
          class="table-container extra-padding static-data"
        >
          <img
            :src="helper.getImage(currentData.table[selectedLanguage], true)"
            :style="`height:${50 * currentData.table.line}px`"
            loading="lazy"
            alt=""
          />
        </div>
        <div
          v-if="currentData.extraImage"
          class="extra-image-container extra-padding static-data"
        >
          <img
            :src="
              helper.getImage(currentData.extraImage[selectedLanguage], true)
            "
            loading="lazy"
            alt=""
          />
        </div>
        <div
          v-if="currentData.technicalData"
          class="technical-container extra-padding"
        >
          <div
            v-for="(tech, key) in currentData.technicalData"
            :key="key"
            class="d-flex flex-column"
          >
            <h6 v-if="tech.title">{{ tech.title[selectedLanguage] }}</h6>
            <span
              v-for="(graph, index) in tech.paragraph"
              :key="index"
              v-html="graph[selectedLanguage]"
            />
          </div>
        </div>
        <div class="extra-container extra-padding">
          <div
            v-for="(extra, key) in currentData.extra"
            :key="key"
            class="extra"
          >
            {{ extra[selectedLanguage] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as materials from "@/crm/materials";
import * as materialDataSheets from "@/crm/materialDataSheets";
import * as helper from "@/helper/helper";
import kvzAccordionMaterial from "@/components/kvz-accordion-material.vue";

export default {
  components: { kvzAccordionMaterial },
  data() {
    return {
      materials: materials,
      materialDataSheets: materialDataSheets,
      helper: helper,
      backTo: {
        EN: "ALL CATEGORIES",
        IT: "TUTTE LE CATEGORIE"
      }
    };
  },
  computed: {
    selectedLanguage() {
      return this.$store.state.values.language;
    },
    contextMaterial() {
      const id = `${this.$route.params.context.toUpperCase()}_${this.$route.params.parent.toUpperCase()}`;
      return materials[id];
    },
    currentData() {
      return this.materialDataSheets[this.$route.params.child.toUpperCase()];
    }
  },
  methods: {
    getTitile() {
      const context = materials.materials.find((element) => {
        return element.path === this.$route.params.context;
      })[this.selectedLanguage];

      const details = materials[
        this.$route.params.context.toUpperCase()
      ].itemsInMainContext.find((element) => {
        return element.path === this.$route.params.parent;
      })[this.selectedLanguage];

      return `${context} | ${details}`;
    },
    changeContext(id) {
      this.$router.push({
        name: "materialsContext",
        params: { context: this.$route.params.context, parent: id }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/_function.scss";
@import "@/scss/_color.scss";
.kvz-materials-container {
  padding: toRem(28) 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .text-context {
    font-style: normal;
    font-weight: 700;
    font-size: toRem(14);
    line-height: toRem(20);
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--blue-color);
    @media screen and (max-width: 600px) {
      width: 300px;
      white-space: break-spaces;
    }
  }
  .option-context {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

navbar {
  :deep(.navbar-nav) {
    --bs-nav-link-color: var(--primary-color);
    --bs-nav-link-hover-color: var(--primary-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
:deep(a:not(.backTo)) {
  color: var(--primary-color);
  text-decoration: none;
  margin: 0 toRem(12);
  font-style: normal;
  font-weight: 500;
  font-size: toRem(14);
  line-height: toRem(20);
  letter-spacing: toRem(0.8);
  position: relative;
  text-transform: uppercase;

  &:hover:not(.navbar-brand) {
    opacity: 80%;
    &::after {
      content: "";
      position: absolute;
      border-top: 2px solid var(--blue-color);
      left: 50%;
      transform: translate(-50%, 0);
      bottom: -4px;
      width: toRem(12);
      height: 0px;
    }
  }
}
.active:not(.navbar-brand),
.router-link-active:not(.navbar-brand) {
  font-weight: 700 !important;
  opacity: 1 !important;
  &::after {
    content: "";
    position: absolute;
    border-top: 2px solid var(--blue-color);
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -4px;
    width: toRem(32) !important; // override hover effect
    height: 0px;
  }
}
//////
.content-wrapper {
  width: 90%;
  margin: toRem(57) 0;
  .material-title {
    font-style: normal;
    font-weight: 700;
    font-size: toRem(24);
    line-height: toRem(24);
    color: var(--accent-color);
    margin-bottom: toRem(16);
  }
  .material-image {
    height: toRem(245);
    width: -webkit-fill-available;
    object-fit: cover;
    border-radius: toRem(8);
  }
  .data-container {
    .data-title {
      margin-top: toRem(24);
      text-transform: uppercase;
      h6 {
        margin: 0 0 0 toRem(4);
        font-style: normal;
        font-weight: 700;
        font-size: toRem(20);
        line-height: toRem(22);
        color: var(--primary-color);
      }
    }
    .data-content {
      margin-top: toRem(4);
      font-style: normal;
      font-weight: 400;
      font-size: toRem(15);
      line-height: toRem(22);
      color: var(--primary-color);
    }
  }
  .line {
    width: 100%;
    height: 2px;
    background: var(--blue-color);
    &.short {
      width: toRem(16);
    }
  }
  .description-container {
    margin-top: toRem(38);
    .description {
      margin-bottom: toRem(22);
      font-style: normal;
      font-weight: 400;
      font-size: toRem(16);
      line-height: toRem(22);
      color: var(--accent-color);
    }
  }
  .extra-container {
    margin-top: toRem(40);
    .extra {
      font-style: normal;
      font-weight: 400;
      font-size: toRem(12);
      line-height: toRem(22);
      color: var(--accent-color);
    }
  }
  .performance-container {
    margin-top: toRem(40);
  }
  .table-container {
    margin-top: toRem(40);
  }
  .extra-image-container {
    margin-top: toRem(40);

    img {
      height: toRem(200);
    }
  }
  .technical-container {
    margin-top: toRem(40);
    * {
      font-size: toRem(16);
      line-height: toRem(22);
      margin: 0;
    }
    h6 {
      color: var(--blue-color);
    }
    span {
      color: var(--primary-color);
      margin: toRem(4) 0;
    }
  }
}
.custom-col {
  padding: 0;
}
.extra-padding {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}
.static-data {
  background: $white;
  width: auto;
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  position: relative;
  --scrollbar-width: 0px;
  --mask-height: calc(var(--bs-gutter-x) * 0.5);
  overflow-y: auto;
  padding-bottom: var(--mask-height);

  --mask-image-content: linear-gradient(
    to right,
    transparent,
    black var(--mask-height),
    black calc(100% - var(--mask-height)),
    transparent
  );
  --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
  --mask-image-scrollbar: linear-gradient(black, black);
  --mask-size-scrollbar: var(--scrollbar-width) 100%;
  mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
  mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
  mask-position: 0 0, 100% 0;

  /* We don't repeat our mask images */
  mask-repeat: no-repeat, no-repeat;
}
</style>
