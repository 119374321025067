<!-- <h6 class="d-flex justify-content-center fs-4">
      <router-link to="/" target="_blank"> www.kiefervonzoe.com </router-link>
    </h6> -->
<template>
  <div class="privacy">
    <h5 class="d-flex justify-content-center fs-3 mb-3">
      <b>PRIVACY POLICY</b>
    </h5>
    <div class="subtitle my-3">
      INFORMATIVA IN MATERIA DI PROTEZIONE DEI DATI PERSONALI AI SENSI DEGLI
      ARTT. 13 E 14 DEL REGOLAMENTO (UE) 2016/679)
    </div>
    <div class="description my-1">
      <b>KIEFER VON ZOE S.r.l.</b>con sede legale in Via Po 41 - 20015 Parabiago
      (Mi), Tel. 0331 422868 – e-mail:
      <a href="mailto:commerciale@kiefervonzoe.com"
        >commerciale@kiefervonzoe.com</a
      >, in qualità di <b>Titolare del trattamento</b> (di seguito, la
      &quot;<b>Società</b>&quot; o il &quot;<b>Titolare</b>&quot;), fornisce di
      seguito le informazioni comuni ai trattamenti dei dati personali
      effettuati nel contesto del proprio sito istituzionale accessibile per via
      telematica a partire dall’indirizzo:
      <router-link to="/" target="_blank"> www.kiefervonzoe.it </router-link>
      (di seguito, il &quot;<b>Sito</b>&quot;). <br class="my-3" />A tale
      proposito, si precisa che l’informativa è resa solo per il Sito e non
      anche per altri siti web eventualmente consultati tramite link
      ipertestuali o widget (es. social network) pubblicati nel Sito, ma
      riferiti a risorse esterne al dominio del Titolare o ai trattamenti che
      dovessero derivare dal volontario invio di messaggi.
    </div>
    <ol class="list my-5" type="1">
      <li class="list my-3">
        CATEGORIE DI INTERESSATI E DATI PERSONALI TRATTATI
        <br />
        Il Titolare tratta i dati personali delle persone fisiche (identificate
        o identificabili) che visitano e consultano il Sito o che nell’ambito
        dello stesso svolgono volontariamente azioni di interazione con il
        Titolare (di seguito, gli &quot;<b>Utente</b>&quot;).
        <br class="my-3" />
        I dati personali trattati sono:
        <ol type="a">
          <li>
            <i>Dati di navigazione</i>: i sistemi informatici e le procedure
            software preposte al funzionamento del Sito acquisiscono, nel corso
            della loro normale attività, alcuni dati personali la cui
            <b>trasmissione è implicita</b> nell&#39;uso dei protocolli di
            comunicazione di Internet. In questa categoria di dati rientrano:
            gli indirizzi IP o i nomi a dominio dei computer e dei terminali
            utilizzati dagli utenti, gli indirizzi in notazione URI/URL (Uniform
            Resource Identifier/Locator) delle risorse richieste, l&#39;orario
            della richiesta, il metodo utilizzato nel sottoporre la richiesta al
            server, la dimensione del file ottenuto in risposta, il codice
            numerico indicante lo stato della risposta data dal server (buon
            fine, errore, etc.) e altri parametri relativi al sistema operativo
            e all&#39;ambiente informatico degli utenti;
          </li>
          <li>
            <i>Dati comunicati</i>: l&#39;invio facoltativo, esplicito e
            volontario di messaggi tramite la compilazione e l’inoltro di form
            presenti sul Sito e/o agli indirizzi di contatto della Società o ai
            profili/pagine istituzionali sui social media (laddove questa
            possibilità sia prevista) comporta l&#39;acquisizione dei dati di
            contatto dell’Utente necessari a rispondere, nonché di tutti gli
            ulteriori ed eventuali dati personali inclusi nel form di
            registrazione o nelle comunicazioni. Specifiche informative verranno
            pubblicate nelle pagine del Sito riportanti il form o predisposte
            per l&#39;erogazione di determinati servizi.
          </li>
          <li>
            <i>Cookie e altri sistemi di tracciamento</i>: per maggiori
            informazioni sulla tipologia di cookie utilizzati, loro gestione di
            utilizzo e finalità,<router-link
              :to="{ name: 'cookie' }"
              target="_blank"
            >
              <b>
                consultare la cookie policy presente sul Sito</b
              > </router-link
            >. (di seguito, tutti, i &#39;<b>Dati Personali</b>&#39;).
          </li>
        </ol>
      </li>
      <li class="list my-3">
        FINALITÀ DEL TRATTAMENTO E BASI GIURIDICHE: <br />
        Il Titolare tratta i Dati Personali raccolti nel contesto del Sito per
        le finalità e in forza delle basi giuridiche indicate nella seguente
        tabella:
        <div class="container my-3">
          <div class="row header">
            <div class="col col-2" />
            <div class="col col-5">
              <u><b> Quali sono le FINALITÀ del trattamento? </b></u>
            </div>
            <div class="col col-5">
              <u><b> Quali sono le BASI GIURIDICHE del trattamento? </b></u>
            </div>
          </div>
          <div class="row">
            <div class="col col-2">1</div>
            <div class="col col-5">
              Adempimento di un obbligo legale connesso a disposizioni
              civilistiche, fiscali e amministrative, di normativa comunitaria,
              di norme, codici o procedure approvati da Autorità e altre
              Istituzioni competenti, nonché per dare seguito a richieste da
              parte dell&#39;autorità amministrativa o giudiziaria competente e,
              più in generale, di soggetti pubblici nel rispetto delle formalità
              di legge.
            </div>
            <div class="col col-5">
              Adempimento di un
              <span class="text-danger">obbligo legale</span>
              al quale è soggetto il Titolare.
            </div>
          </div>
          <div class="row">
            <div class="col col-2">2</div>
            <div class="col col-5">
              Fare valere e difendere i propri diritti, anche mediante
              iniziative stragiudiziali ed anche attraverso terze parti, nonché
              prevenire e rilevare attività fraudolente o l’abuso del Sito (per
              fini potenzialmente delittuosi, come ad esempio per furti
              d’identità, reati informatici, etc.).
            </div>
            <div class="col col-5">
              Perseguimento del
              <span class="text-danger">llegittimo interesse</span>
              del Titolare.
            </div>
          </div>
          <div class="row">
            <div class="col col-2">3</div>
            <div class="col col-5">
              Per consentire agli Utenti di accedere al Sito e navigare in modo
              ottimale e gestire le richieste pervenute attraverso il Sito.
            </div>
            <div class="col col-5">
              Esecuzione di
              <span class="text-danger">
                misure precontrattuali adottate su richiesta dell’Utente.
              </span>
              .
            </div>
          </div>
          <div class="row">
            <div class="col col-2">4</div>
            <div class="col col-5">
              Limitatamente ai dati di navigazione degli Utenti sub par. 1 punto
              a), per finalità di sicurezza dei sistemi del Titolare e per
              ricavare informazioni statistiche sull’uso del Sito (come le
              pagine del Sito più frequentemente visitate, il tempo medio speso
              per ciascuna pagina), nonché per controllare e amministrare il
              funzionamento del Sito e migliorare i servizi forniti.
            </div>
            <div class="col col-5">
              Perseguimento del
              <span class="text-danger">legittimo interesse</span>
              del Titolare.
            </div>
          </div>
          <div class="row">
            <div class="col col-2">5</div>
            <div class="col col-5">
              Per gestire la sezione contatti del sito e quindi per rispondere
              ad eventuali richieste dell’utente pervenute tramite la
              compilazione di eventuali form o tramite l’invio di comunicazioni
              all’indirizzo mail del Titolare
            </div>
            <div class="col col-5">
              Esecuzione di
              <span class="text-danger">
                misure precontrattuali adottate su richiesta dell’Utente
              </span>
              .
            </div>
          </div>
        </div>
      </li>
      <li class="list my-3">
        OBBLIGATORIETÀ DEL RILASCIO DEI DATI RICHIESTI E CONSEGUENZE DEL MANCATO
        RILASCIO <br />
        Salvo quanto specificato per i dati di navigazione (e, nell’apposita
        policy, per la gestione dei cookie), l’utente è libero di fornire i
        propri dati personali (tramite eventuale form - nelle eventuali pagine
        che lo consentono - o con altre modalità ai contatti del Titolare) per
        l’invio di richieste di informazioni o per ricevere comunicazioni
        commerciali.<br class="my-3" />Resta inteso che il loro mancato
        conferimento, anche parziale, può impedire al Titolare lo svolgimento di
        quanto richiesto dall’Utente e delle attività di comunicazione, nonché
        l’assolvimento di eventuali obblighi connessi.
      </li>
      <li class="list my-3">
        MODALITÀ DEL TRATTAMENTO <br />
        I Dati Personali saranno trattati a mezzo di strumenti sia manuali, sia
        informatici automatizzati esclusivamente da parte di soggetti a ciò
        autorizzati ed appositamente istruiti.
      </li>
      <li class="list my-3">
        DESTINATARI/CATEGORIE DI DESTINATARI DEI DATI PERSONALI<br />
        Per le finalità indicate nella presente informativa:<br />
        <ul style="list-style-type: '\2713'">
          <li>i Dati Personali degli Utenti potranno essere comunicati:</li>
          <ul style="list-style-type: disc">
            <li>
              agli autorizzati al trattamento del Titolare (dipendenti o
              collaboratori);
            </li>
            <li>
              a terzi fornitori di servizi al Titolare (tra cui fornitori di
              servizi informatici, hosting provider, web editor, nonché società
              o soggetti che svolgono servizi legali, assicurativi) che
              opereranno, se del caso, in qualità di responsabili del
              trattamento;
            </li>
            <li>
              a società e professionisti terzi incaricati per fare valere
              diritti, interessi, pretese del titolare nascenti dal rapporto con
              gli Utenti;
            </li>
            <li>
              alle Amministrazioni dello Stato, Autorità giudiziarie o
              amministrative, Enti pubblici e privati, anche a seguito di
              ispezioni e verifiche;
            </li>
            <li>
              a soggetti che possono accedere ai dati in forza di disposizioni
              di legge o di normativa secondaria o comunitaria.
            </li>
          </ul>
        </ul>
        Viene indicata solo la categoria dei destinatari, in quanto oggetto di
        continui aggiornamenti. Per conoscere l’elenco aggiornato dei
        destinatari, gli Utenti potranno rivolgersi direttamente al Titolare,
        scrivendo alle coordinate di contatto indicate al par. 9 della presente
        informativa.
      </li>
      <li class="list my-3">
        PERIODI DI CONSERVAZIONE DEI DATI PERSONALI <br />
        I Dati Personali verranno conservati dal Titolare per il tempo
        strettamente necessario alla finalità per cui sono stati raccolti;
        precisamente, il Titolare conserverà:
        <ul style="list-style-type: disc">
          <li>
            i dati di navigazione degli Utenti (indicati al par. 1, lett. a) per
            la durata della sessione di navigazione e comunque non più di sette
            giorni, salvo il caso di disfunzioni nei sistemi, caso in cui
            saranno conservati fino alla risoluzione della problematica;
          </li>
          <li>
            i dati comunicati dagli Utenti (indicati al par. 1, lett. b):
            <ol type="i">
              <li>
                per quanto riguarda i dati personali comunicati mediante la
                compilazione degli eventuali form presenti sul sito web o con
                altre modalità ai contatti del Titolare, per il tempo necessario
                a evadere la relativa richiesta;
              </li>
            </ol>
          </li>
          <li>
            i Dati Personali il cui trattamento è necessario in rapporto ad
            obblighi legali per la durata di legge;
          </li>
        </ul>
        e comunque, ai fini indicati al par. 2, n. 2, al massimo per un periodo
        pari al termine di prescrizione delle azioni rilevanti aumentato di un
        periodo prudenziale di sei mesi, al fine di assicurare il diritto di
        difesa della Società con riferimento a future possibili controversie in
        sede giudiziaria o amministrativa.
        <br class="my-3" />
        In tutti casi, decorsi i rispettivi termini, tutti i Dati Personali
        verranno cancellati o resi anonimi. Resta fermo che i termini indicati
        potranno essere prorogati nei casi in cui la conservazione per un
        periodo ulteriore sia richiesta in occasione di eventuali contenziosi,
        richieste delle autorità competenti o ai sensi della normativa
        applicabile.
      </li>
      <li class="list my-3">
        TRASFERIMENTO DEI DATI PERSONALI A UN PAESE TERZO O A
        UN&#39;ORGANIZZAZIONE INTERNAZIONALE<br />Nell’ambito delle finalità
        sopra esposte è possibile che i Suoi dati vengano trasferiti verso paesi
        appartenenti all’UE.
      </li>
      <li class="list my-3">
        DIRITTI<br />
        Gli Utenti, se ne ricorrono le circostanze, potranno esercitare nei
        confronti del Titolare i seguenti diritti:
        <ul>
          <li>
            <b>Diritto di accesso:</b> permette agli Utenti di ottenere dal
            Titolare la conferma che sia o meno in corso un trattamento di Dati
            Personali che lo riguardano e, in tale caso, di ottenere
            l&#39;accesso ai propri dati personali;
          </li>

          <li>
            <b>Diritto di rettifica:</b> permette agli Utenti di ottenere la
            rettifica/integrazione dei Dati Personali inesatti/incompleti;
          </li>
          <li>
            <b>Diritto alla cancellazione:</b> permette agli Utenti di ottenere,
            nei casi previsti dalla normativa, la cancellazione dei propri dati
            personali;
          </li>
          <li>
            <b>Diritto di limitazione di trattamento:</b> permette agli Utenti
            di ottenere, nei casi previsti dall&#39;art. 18, paragrafo 1 del
            GDPR, la limitazione (cioè il contrassegno dei dati personali
            conservati con l&#39;obiettivo di limitarne il trattamento in
            futuro) del trattamento dei propri dati personali;
          </li>
          <li>
            <b>Diritto alla portabilità dei dati:</b> permette agli Utenti -
            nelle ipotesi in cui il trattamento sia effettuato con mezzi
            automatizzati sulla base giuridica del contratto o del consenso - di
            ricevere in un formato strutturato, di uso comune e leggibile da
            dispositivo automatico, limitatamente ai dati forniti al Titolare, i
            dati personali che lo riguardano e analogamente il diritto di
            trasmettere tali dati a un altro titolare del trattamento.
          </li>
        </ul>
        Inoltre, gli Utenti hanno diritto:
        <ul style="list-style-type: disc">
          <li>
            <b>di opporsi</b> al trattamento dei propri Dati Personali per le
            finalità indicate al paragrafo 2;
          </li>
          <li>
            nonché, qualora ritengano che il trattamento dei Dati Personali a
            loro riferiti effettuato attraverso questo Sito avvenga in
            violazione di quanto previsto dal GDPR,
            <b>di proporre reclamo</b> ai sensi dell’art. 77 del GDPR,
            all’Autorità nazionale di supervisione dello stato membro
            dell’Unione Europea in cui l’Interessato ha la propria residenza
            abituale o luogo di lavoro od ove sia avvenuta l’asserita violazione
            del suo diritto (nel caso tale Stato sia l’Italia, il soggetto cui
            potrà rivolgersi è l’Autorità Garante per la protezione dei dati
            personali) <b>o di adire le opportune sedi giudiziarie</b> (art. 79
            del GDPR).
          </li>
        </ul>
      </li>
      <li class="list my-3">
        CONTATTI<br />Per esercitare tutti i diritti, l’interessato può
        presentare apposita istanza contattando il Titolare con le seguenti
        modalità:
        <ul style="list-style-type: disc">
          <li>a mezzo posta: <b>Via Po 41 - 20015 Parabiago (MI)</b>;</li>
          <li>
            inviando una e-mail alla casella di posta elettronica ordinaria
            e-mail
            <a href="mailto:commerciale@kiefervonzoe.com"
              >commerciale@kiefervonzoe.com</a
            >
          </li>
        </ul>
      </li>
      <li class="list my-3">
        MODIFICHE<br />
        La presente informativa sulla privacy è stata aggiornata in data
        14/06/2022<br class="my-3" />TLa Società si riserva il diritto di
        modificare parzialmente o integralmente la presente informativa o
        aggiornarne il contenuto, ad es. in seguito a modifiche della legge
        applicabile. Pertanto, la Società invita l’Utente a consultare
        regolarmente l’informativa per conoscerne l’ultima versione aggiornata
        in modo da essere sempre informati circa il modo di raccolta e utilizzo
        dei Dati Personali.
      </li>
    </ol>
  </div>
</template>
<style scoped lang="scss">
.privacy {
  color: var(--primary-color);
  line-height: 1.5;
}
.col {
  border: 1px solid var(--primary-color);
  padding: 1rem 0.5rem;
}
</style>
