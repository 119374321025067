export const routes = [
  { path: "/", name: "home-page", component: () => import("../view/HomePage") },
  {
    path: "/about-us",
    name: "about-us",
    component: () => import("../view/AboutUs")
  },
  {
    path: "/latest",
    name: "latest",
    component: () => import("../view/Latest")
  },
  {
    path: "/latest/:id",
    name: "article",
    component: () => import("../view/Article")
  },
  {
    path: "/contacts",
    name: "contacts",
    component: () => import("../view/Contacts")
  },
  {
    path: "/materials",
    name: "materials",
    component: () => import("../view/Materials")
  },
  {
    path: "/materials/:context/:parent",
    name: "materialsContext",
    component: () => import("../view/MaterialContext")
  },
  {
    path: "/materials/:context/:parent/:child",
    name: "materialsContextParent",
    component: () => import("../view/MaterialContextParent")
  },
  {
    path: "/sustainability",
    name: "sustainability",
    component: () => import("../view/Sustainability")
  },
  // {
  //   path: "/kvz-consulting",
  //   name: "kvz-consulting",
  //   component: () => import("../view/KVZconsulting")
  // },
  {
    path: "/info",
    name: "info",
    component: () => import("../view/Info")
  },
  {
    path: "/cookie-policy",
    name: "cookie",
    component: () => import("../view/Cookie")
  },
  {
    path: "/privacy-policy",
    name: "privacy",
    component: () => import("../view/Privacy")
  },

  {
    path: "/:pathMatch(.*)*",
    component: () => import("../view/HomePage")
  }
];
